import { useState } from 'react'
import { Button, ButtonProps } from '@rneui/themed'

import forgotPassword from '@/api/auth/forgotPassword'
import { xConsole } from '@/plugins/helpers/xConsole'

type ForgotPasswordButtonProps = {
  phoneNumber: string
  buttonProps?: ButtonProps
}
function ForgotPasswordButton({ phoneNumber = '', buttonProps = {} }: ForgotPasswordButtonProps) {
  const [isDisabled, setIsDisabled] = useState(false)
  const onPress = async () => {
    try {
      setIsDisabled(true)
      const res = await forgotPassword({ phoneNumber })
      if (res.status === 200) {
        toast.show('Vi har sent deg en link for å tilbakestille passordet ditt via SMS', { type: 'main', data: { title: 'Sent!', icon: 'success' } })
      } else {
        toast.show('Beklager, noe gikk galt.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
        setIsDisabled(false)
      }
    } catch (error) {
      toast.show('Beklager, noe gikk galt.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      setIsDisabled(false)
      xConsole().error(error as Error, 'ResetPasswordButton.tsx (Main)')
    }
  }
  return <Button title={'Glemt passord'} {...buttonProps} disabled={isDisabled} onPress={onPress} />
}

export default ForgotPasswordButton
