import { useEffect, useState } from 'react'
import { StyleSheet, View, AppState } from 'react-native'
import { Text, Icon, Button } from '@rneui/themed'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import axios from 'axios'
import { debounce } from 'lodash'

import theme from '@/config/themes'

// Types

const EmergencyMessage = () => {
  const insets = useSafeAreaInsets()
  const [isClose, setIsClose] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    getMessage()
    const listener = AppState.addEventListener('change', debounce(getMessage, 3000))
    return () => listener.remove()
  }, [])

  const getMessage = async () => {
    try {
      const { data } = await axios.get('https://json.kopling.no/message.json', {
        transformRequest: (data, headers) => {
          delete headers?.common['Authorization']
          return data
        },
      })
      setMessage(data.message)
    } catch (error) {}
  }

  if (isClose || !message) return null
  return (
    <View style={[styles.mainContainer, { paddingTop: insets.top }]}>
      <Icon type="antdesign" name="exclamationcircle" size={20} color={theme.colors.error} containerStyle={styles.iconContainer} />
      <Text style={styles.text}>{message}</Text>
      <Button
        title="LUKK"
        type="clear"
        buttonStyle={[theme._.ButtonStyle.Clear]}
        containerStyle={styles.closeButtonContainer}
        onPress={() => {
          setIsClose(true)
        }}
      />
    </View>
  )
}

export default EmergencyMessage

const styles = StyleSheet.create({
  mainContainer: {
    position: 'absolute',
    width: '100%',
    maxWidth: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255,255,255,0.95)',
    paddingHorizontal: 20,
    paddingVertical: 10,
    zIndex: 10,
  },
  iconContainer: {
    marginRight: 20,
  },
  text: {
    flex: 1,
    fontSize: 14,
    color: '#666666',
  },
  closeButtonContainer: {
    marginLeft: 20,
  },
})
