/*
  Get target groups by current user
  Params: IUser
  Return: Array<string>
*/

// Types
import type { IUser } from '@/types'
import { xConsole } from './xConsole'

const getTargetGroupsByUser = (user: IUser) => {
  const groups = ['all']
  try {
    // Roles
    user.roles.forEach((v) => {
      groups.push(v)
    })

    // County
    if (user.countyId) {
      groups.push(user.countyId)
    }

    // Institutions
    user.institutions.forEach((v) => {
      groups.push(v.institutionId)
    })
  } catch (error) {
    xConsole().error(error as Error, 'getTargetGroupsByUser')
  }
  return groups
}

export default getTargetGroupsByUser
