import { SystemMessage } from 'react-native-gifted-chat'

// Components
import ChatSystemMessageContactCard from '@/components/chat/SystemMessageContactCard'
import ChatSystemMessageParticipantRemoved from '@/components/chat/SystemMessageParticipantRemoved'

// Types
import type { IMessage } from '@/types'
import type { SystemMessageProps } from 'react-native-gifted-chat'
type ChatSystemMessageProps = SystemMessageProps<IMessage> & {
  isAnonymous: boolean
}

function ChatSystemMessage(props: ChatSystemMessageProps) {
  const { currentMessage, isAnonymous } = props
  if (currentMessage && currentMessage.metaData?.customType) {
    if (currentMessage.metaData.customType === 'contactCardSystemMessage') {
      return <ChatSystemMessageContactCard {...props} isAnonymous={isAnonymous} />
    } else if (currentMessage.metaData.customType === 'participantRemoved') {
      return <ChatSystemMessageParticipantRemoved {...props} isAnonymous={isAnonymous} />
    }
  }
  return <SystemMessage {...props} />
}

export default ChatSystemMessage
