import { StyleSheet } from 'react-native'
import { Text } from '@rneui/themed'

type StatusMessageProps = {
  message: string
}
function StatusMessage({ message }: StatusMessageProps) {
  return <Text style={styles.text}>{message}</Text>
}

const styles = StyleSheet.create({
  text: {
    fontSize: 22,
    marginTop: 10,
  },
})

export default StatusMessage
