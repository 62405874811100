/*
  Condition for check token is expired or not (example: before requests)
  Return: boolean
*/

import { getStore } from '@/config'
import moment from 'moment'

const isTokenExpired = () => {
  let isExpired = false

  try {
    const { token, user } = getStore().getState().user
    if (!user?.institutions?.some((v) => v.institutionId === 'ba9abd74390b45b397260fe1ec288998')) return false // Enable this just for Greåker videregående skole
    const expiresDiffHours = moment(token.expires_date).diff(new Date(), 'hours')
    isExpired = expiresDiffHours <= 3
  } catch (error) {}

  return isExpired
}

export default isTokenExpired
