import { WebView } from 'react-native-webview'

// Components
import ScreenSubpageLayout from '../../components/settings/ScreenSubpageLayout'

// Variables

const SettingsAdminScreen = () => {
  return (
    <ScreenSubpageLayout title="Administrasjon">
      <WebView style={{ marginVertical: 15 }} source={{ uri: 'https://web-admin.kopling.no?v4' }} />
    </ScreenSubpageLayout>
  )
}

export default SettingsAdminScreen
