import { ListItem } from '@rneui/themed'
import React, { useState } from 'react'
import { View } from 'react-native'
import { Text, Icon } from '@rneui/themed'
import Markdown from 'react-native-markdown-renderer'

import theme from '@/config/themes'

// Types
import type { IFAQ, IMainFAQ, ISubFAQ } from '@/types'
type FAQListItemProps = {
  item: IFAQ
}

function FAQListItem({ item }: FAQListItemProps) {
  // Accordions
  const [mainFAQExpanded, setMainFAQExpanded] = useState<String>('')
  const [subFAQExpanded, setSubFAQExpanded] = useState<String>('')

  return (
    <View style={{ marginBottom: 40 }}>
      <Text h4 h4Style={{ color: theme.colors.primary }}>
        {item.assignedName}
      </Text>
      {item.faqs.map((mainFAQ: IMainFAQ, mainFAQIndex: React.Key) => (
        <View key={mainFAQIndex}>
          <ListItem.Accordion
            containerStyle={{
              backgroundColor: 'transparent',
              borderBottomColor: theme.colors.divider,
              borderBottomWidth: 1,
              paddingBottom: 15,
              paddingLeft: 0,
            }}
            content={
              <ListItem.Content>
                <ListItem.Title
                  style={{
                    fontSize: 20,
                    fontFamily: mainFAQ.content?.contentId === mainFAQExpanded ? 'FSJoeyMedium' : 'FSJoey',
                  }}
                >
                  {mainFAQ.content?.title}
                </ListItem.Title>
              </ListItem.Content>
            }
            icon={{
              type: 'entypo',
              name: 'chevron-down',
              color: theme.colors.primary,
            }}
            expandIcon={{
              type: 'entypo',
              name: 'chevron-up',
              color: theme.colors.primary,
            }}
            isExpanded={mainFAQ.content?.contentId === mainFAQExpanded}
            bottomDivider
            onPress={() => {
              const to = mainFAQExpanded !== mainFAQ.content?.contentId ? mainFAQ.content?.contentId : ''
              setMainFAQExpanded(to)
              setSubFAQExpanded('')
            }}
          >
            <View
              style={{
                backgroundColor: 'transparent',
                paddingBottom: 15,
                borderBottomWidth: 1,
                borderBottomColor: theme.colors.divider,
              }}
            >
              {!!mainFAQ.content?.content && (
                <View
                  style={{
                    padding: 15,
                  }}
                >
                  <Markdown style={theme._.MarkdownStyle}>{mainFAQ.content?.content || ''}</Markdown>
                </View>
              )}
              {mainFAQ.subFaqs?.map((subFAQ: ISubFAQ, subFAQIndex: React.Key) => (
                <ListItem.Accordion
                  bottomDivider
                  containerStyle={{
                    backgroundColor: 'transparent',
                    borderBottomWidth: 0,
                    paddingVertical: 10,
                    paddingLeft: 0,
                    alignItems: 'flex-start',
                  }}
                  key={subFAQIndex}
                  content={
                    <>
                      <Icon
                        type="entypo"
                        name="dot-single"
                        containerStyle={{
                          marginTop: -2,
                          width: 25,
                        }}
                      />
                      <ListItem.Content>
                        <ListItem.Title
                          style={{
                            fontSize: 18,
                            fontFamily: subFAQ?.subContent?.contentId === subFAQExpanded ? 'FSJoeyMedium' : 'FSJoey',
                          }}
                        >
                          {subFAQ?.subContent?.title}
                        </ListItem.Title>
                      </ListItem.Content>
                    </>
                  }
                  icon={{
                    type: 'entypo',
                    name: 'chevron-down',
                    color: theme.colors.primary,
                  }}
                  expandIcon={{
                    type: 'entypo',
                    name: 'chevron-up',
                    color: theme.colors.primary,
                  }}
                  isExpanded={subFAQ?.subContent?.contentId === subFAQExpanded}
                  onPress={() => {
                    const to = subFAQExpanded !== subFAQ?.subContent?.contentId ? subFAQ?.subContent?.contentId : ''
                    setSubFAQExpanded(to)
                  }}
                >
                  <View
                    style={{
                      paddingBottom: 15,
                      paddingLeft: 0,
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                    }}
                  >
                    <Markdown style={theme._.MarkdownStyle}>{subFAQ?.subContent?.content || ''}</Markdown>
                  </View>
                </ListItem.Accordion>
              ))}
            </View>
          </ListItem.Accordion>
        </View>
      ))}
    </View>
  )
}

export default FAQListItem
