import { useCallback } from 'react'
import { StyleSheet, View, Pressable, ActivityIndicator } from 'react-native'
import { Text, Image, Icon } from '@rneui/themed'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Components
import Tags from '@/components/contacts/organizations/Tags'
import ContactIcons from '@/components/contacts/organizations/ContactIcons'

// Themes
import theme from '@/config/themes'

// Types
import type { RootNavigatorParamList } from '@/types'
import { Routes } from '@/config/routes'
type ItemContentProps = {
  data: any
}

const ItemContent = ({ data }: ItemContentProps) => {
  if (!data.id && !data.data) {
    return <></>
  }

  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()

  const onPress = useCallback(() => {
    navigation.navigate(Routes.OrganizationContactsPage, {
      id: data.id,
    })
  }, [data])

  return (
    <Pressable onPress={onPress} style={[styles.mainContainer, { backgroundColor: data.data.isSecondaryBackground ? '#f8f8f8' : '#fff' }]}>
      <View style={[styles.headerContainer, { flexDirection: data.data.logo?.[0].isHorizontal ? 'column' : 'row' }]}>
        {!!data.data.logo?.[0] && (
          <Image
            source={{ uri: data.data.logo[0].url }}
            containerStyle={[styles.image, { maxWidth: data.data.logo?.[0].isHorizontal ? 100 : 50 }]}
            PlaceholderContent={<ActivityIndicator />}
            placeholderStyle={styles.imagePlaceholder}
            resizeMode={'center'}
          />
        )}
        {!!data.data.title && (
          <Text style={[styles.title, { alignSelf: data.data.logo?.[0].isHorizontal ? 'flex-start' : 'center' }]}>{data.data.title}</Text>
        )}
      </View>

      <Tags tags={data.data.tags} containerStyle={styles.tagsContainer} />

      <View style={styles.footerContainer}>
        <View>
          <ContactIcons chatUrl={data.data.chatUrl} phoneNumber={data.data.phoneNumber} organizationId={data.data.organizationId} />
        </View>
        <Icon type="entypo" name="chevron-right" size={24} color={theme.colors.primary} />
      </View>
    </Pressable>
  )
}

export default ItemContent

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    overflow: 'hidden',
    paddingVertical: 10,
    paddingHorizontal: 20,
    minHeight: 220,
  },

  /* Header */
  headerContainer: {
    flexDirection: 'row',
    marginVertical: 5,
  },
  image: {
    flex: 1,
    height: 50,
    marginRight: 10,
  },
  imagePlaceholder: {
    backgroundColor: 'transparent',
    flex: 1,
  },
  title: {
    flex: 1,
    fontFamily: 'FSJoeyMedium',
    fontSize: 18,
  },

  /* Tags */
  tagsContainer: {
    marginVertical: 5,
  },
  tagContainer: {
    backgroundColor: '#f0f0f0',
    borderRadius: 11,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  tag: {
    fontSize: 14,
    color: '#2b2c2b',
  },

  /* Footer */
  footerContainer: {
    paddingVertical: 10,
    marginVertical: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
