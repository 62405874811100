import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { Text } from '@rneui/themed'

// Types
type TagsArgs = {
  tags: Array<string>
  containerStyle?: StyleProp<ViewStyle>
  tagsContainerStyle?: StyleProp<ViewStyle>
}
const Tags = ({ tags, containerStyle, tagsContainerStyle }: TagsArgs) => {
  return (
    <View style={[styles.mainContainer, containerStyle]}>
      {tags?.map((v: any, i: number) => (
        <View key={i} style={[styles.tagsContainer, tagsContainerStyle]}>
          <Text style={styles.tag}>{v}</Text>
        </View>
      ))}
    </View>
  )
}

export default Tags

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    gap: 5,
    flexGrow: 1,
  },
  tagsContainer: {
    backgroundColor: '#f0f0f0',
    borderRadius: 20,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  tag: {
    fontSize: 14,
    color: '#2b2c2b',
  },
})
