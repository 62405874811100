import { useEffect, useState, forwardRef, useCallback, useImperativeHandle } from 'react'
import { Platform, Dimensions, StyleSheet, View, ActivityIndicator } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import { Text, Icon } from '@rneui/themed'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import uFuzzy from '@leeoniya/ufuzzy'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { fetchUser } from '@/slices/userSlice'
import { fetchCounties } from '@/slices/institutionsSlice'

// Components
import SearchCounties from '@/components/institutions/countySelector/Search'
import CountyItem from '@/components/institutions/countySelector/Item'

// APIs
import setProfileInfo from '@/api/auth/setProfileInfo'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Theme
import theme from '@/config/themes'

// Types
import type { ICounty } from '@/types'
type CountyListSelectorProps = {
  onSelect?: Function
  onSubmit?: Function
  defaultSelected?: string
}

// Variables
const { height } = Dimensions.get('window')

const CountySelectorList = forwardRef(({ onSelect, onSubmit, defaultSelected = '' }: CountyListSelectorProps, ref) => {
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const insets = useSafeAreaInsets()

  useImperativeHandle(ref, () => ({
    setIsActive,
  }))

  // State
  const { user } = useSelector((state: RootState) => state.user)
  const { counties } = useSelector((state: RootState) => state.institutions)
  const [countiesFiltered, setCountiesFiltered] = useState<ICounty[]>([])

  useEffect(() => {
    dispatch(fetchCounties())
  }, [])

  const onSelectItem = async (active: boolean, id: string) => {
    setIsLoading(true)

    try {
      if (onSelect) {
        onSelect(active, id)
      }
    } catch (error: any) {
      xConsole().error(error as Error, 'institutions/countySelector/List.ts')
    } finally {
      setIsLoading(false)
    }
  }

  const renderItem = useCallback(
    ({ item }) => {
      let isActive = defaultSelected === item.countyId
      return <CountyItem item={item} onPress={(active, id) => onSelectItem(active, id)} active={isActive} loading={isLoading} />
    },
    [user, isLoading, defaultSelected]
  )

  const onSearch = useCallback(
    (s: string) => {
      try {
        if (s.length) {
          const result: ICounty[] = []
          const uf = new uFuzzy({ intraMode: 1, intraIns: 1, intraSub: 1, intraTrn: 1, intraDel: 1 })
          const formatted = Object.entries([...counties]).map(([k, v]) => v.countyName)
          const idxs = uf.filter(formatted, s)
          const info = uf.info(idxs, formatted, s)
          const order = uf.sort(info, formatted, s)
          for (const i of order) {
            const item = counties[info.idx[i]]
            result.push(item)
          }
          setCountiesFiltered(result)
        } else {
          setCountiesFiltered([])
        }
      } catch (error) {
        xConsole().error(error as Error, 'institutions/countySelector/List.tsx (onSearch)')
      }
    },
    [counties]
  )

  return (
    <View style={styles.listContainer}>
      <Text h4 style={{ paddingHorizontal: 15 }}>
        Velg fylke
      </Text>
      <SearchCounties
        onSearch={(s: string) => {
          onSearch(s)
        }}
      />
      {counties.length ? (
        <FlatList
          data={countiesFiltered.length > 0 ? countiesFiltered : counties}
          keyExtractor={(item: any, index: any) => index}
          renderItem={renderItem}
          style={{ marginTop: 10 }}
          contentInset={{ bottom: insets.bottom }}
          testID="CountySelectorList_BottomSheetFlatList"
        />
      ) : (
        <View style={{ flex: 1, height: '100%', justifyContent: 'center' }}>
          <ActivityIndicator color={theme.colors.black} testID="CountySelectorList_View_ActivityIndicator" />
        </View>
      )}
      {defaultSelected && (
        <Icon
          type="octicon"
          name="arrow-right"
          size={48}
          color={theme.colors.white}
          containerStyle={styles.fabContainer}
          onPress={() => {
            if (onSubmit) {
              onSubmit({ reference: 'click-arrow' })
            }
          }}
        />
      )}
    </View>
  )
})

export default CountySelectorList

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 200,
  },
  contentContainer: {
    backgroundColor: 'white',
  },
  itemContainer: {
    padding: 6,
    margin: 6,
    backgroundColor: '#eee',
  },
  listContainer: {
    flex: 1,
    ...Platform.select({
      web: {
        maxHeight: height * 0.7 - 70,
      },
    }),
  },
  fabContainer: {
    position: 'absolute',
    bottom: 100,
    right: 20,
    width: 66,
    height: 66,
    backgroundColor: theme.colors.primary,
    borderRadius: 100,
    justifyContent: 'center',
  },
})
