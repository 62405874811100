import { StyleSheet } from 'react-native'
import { Icon } from '@rneui/themed'
import { Send } from 'react-native-gifted-chat'

function InputSend(props: any) {
  const { isAnonymous } = props
  return (
    <Send {...props} containerStyle={styles.mainContainer}>
      <Icon name="send" type="ionicon" size={20} color={!isAnonymous ? '#2b2c2b' : '#ffffff'} />
    </Send>
  )
}

export default InputSend

const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    padding: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    marginHorizontal: 0,
    marginBottom: 0,
    height: 40,
    width: 40,
  },
})
