import { SafeAreaView } from 'react-native-safe-area-context'

// Components
import ListChannels from '@/components/channels/List'
import ContactsOverlay from '@/components/contacts/institutions/Overlay'
import ScreenTitle from '@/components/ScreenTitle'
import TabMenus from '@/components/TabMenus'

const ChannelsScreen = () => {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScreenTitle title="Dine meldinger" />
      <ListChannels />
      <TabMenus />
      <ContactsOverlay />
    </SafeAreaView>
  )
}

export default ChannelsScreen
