import axios, { AxiosResponse } from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type ISetAvailabilityReturn = Promise<AxiosResponse>
import type { IAvailabilityName } from '@/types'

/*
  Set user's custom availability (online status)
  Example usage: In setting page
*/
async function setAvailability(availabilityName: IAvailabilityName): ISetAvailabilityReturn {
  try {
    const res = await axios.put(`/api/profilesettings/availability?profileStatus=${availabilityName}`)
    return res
  } catch (error: any) {
    xConsole().error(error, 'setAvailability')
    return error.response as AxiosResponse
  }
}

export default setAvailability
