import { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, Input, Button, Icon } from '@rneui/themed'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// APIs
import changePassword from '@/api/auth/changePassword'

// Components
import ForgotPasswordButton from '@/components/login/ForgotPasswordButton'

// Themes
import theme from '@/config/themes'

const SettingsPassword = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.user)
  const [isLoading, setIsLoading] = useState(false)
  const [passwordInputs, setPasswordInputs] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' })
  const [invalidMessages, setInvalidMessages] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' })
  const [isResponseFromApi, setIsResponseFromApi] = useState(false)

  if (!user) {
    return null
  }

  const doChangePassword = async () => {
    setIsResponseFromApi(false)
    let isInvalid = false
    const messages = { currentPassword: '', newPassword: '', confirmPassword: '' }
    setIsLoading(true)

    if (!passwordInputs.currentPassword) {
      messages.currentPassword = 'Nåværende passord er obligatorisk.'
      isInvalid = true
    }
    if (!passwordInputs.newPassword) {
      messages.newPassword = 'Nytt passord er obligatorisk.'
      isInvalid = true
    }
    if (!passwordInputs.confirmPassword) {
      messages.confirmPassword = 'Gjenta nytt passord er obligatorisk.'
      isInvalid = true
    }
    if (passwordInputs.newPassword && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(passwordInputs.newPassword)) {
      // https://stackoverflow.com/a/40923568/622813
      messages.newPassword = 'Minst 8 tegn, inneholde både store og små bokstaver, samt minst ett tall.'
      isInvalid = true
    }

    if (passwordInputs.confirmPassword && passwordInputs.newPassword !== passwordInputs.confirmPassword) {
      messages.confirmPassword = 'Nytt passordet og gjenta nytt passordet er ikke samsvarende'
      isInvalid = true
    }

    if (!isInvalid) {
      setIsResponseFromApi(true)
      const res = await changePassword({ currentPassword: passwordInputs.currentPassword, newPassword: passwordInputs.newPassword })
      if (res.status === 200) {
        toast.show('Du har byttet passordet, logg deg inn på nytt.', { type: 'main', data: { title: 'Byttet!', icon: 'success' } })
      } else if (res.status === 403) {
        messages.currentPassword = 'Nåværende passord er feil.'
        toast.show('Nåværende passord er feil.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      } else {
        toast.show('Beklager, noe gikk galt.'), { type: 'main', data: { title: 'Obs!', icon: 'error' } }
      }
    }
    setInvalidMessages(messages)
    setIsLoading(false)
  }

  const onChangeText = (k: 'currentPassword' | 'newPassword' | 'confirmPassword', v: string) => {
    setPasswordInputs({ ...passwordInputs, ...{ [k]: v } })
  }

  const renderLabel = (title: string, key: 'currentPassword' | 'newPassword' | 'confirmPassword') => {
    if (!passwordInputs[key].length) {
      return
    }
    return <Text style={styles.inputLabel}>{title}</Text>
  }
  const errorMessage = (key: 'currentPassword' | 'newPassword' | 'confirmPassword') => {
    return !isResponseFromApi ? invalidMessages[key] : ''
  }

  return (
    <View>
      <Input
        containerStyle={styles.mainInputContainer}
        inputContainerStyle={styles.inputContainer}
        inputStyle={styles.input}
        errorStyle={styles.inputErrorText}
        placeholder="Nåværende passord"
        label={renderLabel('Nåværende passord', 'currentPassword')}
        onChangeText={(v) => onChangeText('currentPassword', v)}
        secureTextEntry
        disabled={isLoading}
        errorMessage={errorMessage('currentPassword')}
      />
      <Input
        containerStyle={styles.mainInputContainer}
        inputContainerStyle={styles.inputContainer}
        inputStyle={styles.input}
        errorStyle={styles.inputErrorText}
        placeholder="Nytt passord"
        label={renderLabel('Nytt passord', 'newPassword')}
        onChangeText={(v) => onChangeText('newPassword', v)}
        secureTextEntry
        disabled={isLoading}
        errorMessage={errorMessage('newPassword')}
      />
      <Input
        containerStyle={styles.mainInputContainer}
        inputContainerStyle={styles.inputContainer}
        inputStyle={styles.input}
        errorStyle={styles.inputErrorText}
        placeholder="Gjenta nytt passord"
        label={renderLabel('Gjenta nytt passord', 'confirmPassword')}
        onChangeText={(v) => onChangeText('confirmPassword', v)}
        secureTextEntry
        disabled={isLoading}
        errorMessage={errorMessage('confirmPassword')}
      />

      <Button title={'LAGRE PASSORD'} onPress={doChangePassword} loading={isLoading} containerStyle={{ marginTop: 40 }} />
      <ForgotPasswordButton
        phoneNumber={user.phones[0].value}
        buttonProps={{ loading: isLoading, type: 'clear', containerStyle: { marginBottom: 40 } }}
      />
      {isResponseFromApi &&
        Object.keys(invalidMessages).map(
          (k) =>
            !!invalidMessages[k].length && (
              <View style={{ marginBottom: 10, flexDirection: 'row', justifyContent: 'space-between' }} key={k}>
                <Text style={{ fontFamily: 'FSJoeyMedium', fontSize: 14, paddingRight: 20 }}>{invalidMessages[k]}</Text>
                <Icon type="antdesign" name="exclamationcircle" size={15} color={theme.colors.error} />
              </View>
            )
        )}
    </View>
  )
}

export default SettingsPassword

const styles = StyleSheet.create({
  mainInputContainer: {
    paddingHorizontal: 0,
  },
  buttonDisabledContainer: {
    opacity: 0.5,
    backgroundColor: theme.colors.primary,
  },
  buttonDisabledTitle: {
    color: theme.colors.white,
  },

  inputLabel: {
    fontFamily: 'FSJoeyMedium',
  },
  inputContainer: {
    borderColor: theme.colors.light,
  },
  input: {
    fontSize: 16,
    minHeight: 35,
  },
  inputErrorText: {
    color: theme.colors.primary,
    fontSize: 9,
    marginLeft: 0,
    height: 10,
  },
  buttonContainer: {
    marginVertical: 15,
  },
  buttonActive: {
    backgroundColor: theme.colors.primary,
  },
  buttonInactive: {
    backgroundColor: 'transparent',
    borderWidth: 2,
  },
  buttonTitleActive: {
    color: theme.colors.white,
  },
  buttonTitleInactive: {
    color: theme.colors.black,
  },
})
