import { View } from 'react-native'
import { Text } from '@rneui/themed'

// Components
import ScreenSubpageLayout from '@/components/settings/ScreenSubpageLayout'
import SettingsPassword from '@/components/settings/Password'
// import BiometricAuthentication from '@/components/settings/BiometricAuthentication'

// Variables

const SettingsSecurityScreen = () => {
  return (
    <ScreenSubpageLayout title="Endre passord">
      <View style={{ marginTop: 30, marginBottom: 10 }}>
        <Text style={{ fontSize: 18 }}>Passordet ditt må være minst 8 tegn, inneholde både store og små bokstaver, samt minst ett tall.</Text>
      </View>
      <View style={{ marginTop: 10 }}>
        <SettingsPassword />
      </View>
    </ScreenSubpageLayout>
  )
}

export default SettingsSecurityScreen
