import { useState, useRef } from 'react'
import { View } from 'react-native'

// Components
import ScreenSubpageLayout from '@/components/settings/ScreenSubpageLayout'
import SettingsAvatarUpload from '@/components/settings/AvatarUpload'
import SettingsEditInfo from '@/components/settings/EditInfo'
import AvatarUploadOverlay from '@/components/settings/AvatarUploadOverlay'

// Types
import { IAvatarUploadOverlayRef } from '@/types'

const SettingsProfileScreen = () => {
  const avatarUploadOverlayRef = useRef<IAvatarUploadOverlayRef>(null)
  const [newDefaultAvatarId, setNewDefaultAvatarId] = useState('')
  const openAvatarUploadOverlay = () => {
    if (!avatarUploadOverlayRef.current) {
      return
    }
    avatarUploadOverlayRef.current.setIsActive(true)
  }

  return (
    <>
      <ScreenSubpageLayout title="Din profil" withKeyboardAvoidingView>
        <>
          <View style={{ marginTop: 30, marginBottom: 10 }}>
            <SettingsAvatarUpload onPress={openAvatarUploadOverlay} newDefaultAvatarId={newDefaultAvatarId} />
          </View>
          <View>
            <SettingsEditInfo newDefaultAvatarId={newDefaultAvatarId} />
          </View>
        </>
      </ScreenSubpageLayout>
      <AvatarUploadOverlay ref={avatarUploadOverlayRef} onSelect={setNewDefaultAvatarId} />
    </>
  )
}

export default SettingsProfileScreen
