import { useState, useCallback, useMemo, useRef, forwardRef, useImperativeHandle } from 'react'
import { StyleSheet, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { Text, Divider } from '@rneui/themed'
import { BottomSheetModal, BottomSheetBackdrop } from '@gorhom/bottom-sheet'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Components
import SettingsAvatarUpload from './AvatarUpload'
import SettingsAvatarList from './AvatarList'

// Types
type SettingsAvatarUploadOverlayProps = {
  onSelect?: (avatarId: string) => void
}

const SettingsAvatarUploadOverlay = forwardRef(({ onSelect }: SettingsAvatarUploadOverlayProps, ref) => {
  const { user } = useSelector((state: RootState) => state.user)
  if (!user) {
    return null
  }

  const [newDefaultAvatarId, setNewDefaultAvatarId] = useState('')
  const bottomSheetModalRef = useRef<BottomSheetModal>(null)

  const snapPoints = useMemo(() => ['40%', '75%'], [])

  useImperativeHandle(ref, () => ({
    setIsActive(isActive: Boolean) {
      if (isActive) {
        bottomSheetModalRef.current?.present()
      }
    },
    newDefaultAvatarId,
  }))

  const avatarOnChange = (avatarId: string, dismissBottomSheetModal = false) => {
    setNewDefaultAvatarId(avatarId)
    if (!!onSelect) {
      onSelect(avatarId)
    }
    if (dismissBottomSheetModal) {
      bottomSheetModalRef.current?.dismiss()
    }
  }

  const renderBackdrop = useCallback((props) => <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} />, [])

  return (
    <BottomSheetModal ref={bottomSheetModalRef} index={1} snapPoints={snapPoints} backdropComponent={renderBackdrop}>
      <ScrollView style={{ flex: 1 }}>
        <View style={{ marginVertical: 15 }}>
          <SettingsAvatarUpload isOnPressShouldDoUpload newDefaultAvatarId={newDefaultAvatarId} />
        </View>
        <View style={{ paddingHorizontal: 30 }}>
          <Divider />
          <Text style={{ padding: 10, marginVertical: 15, fontSize: 18 }}>
            Hvis du ikke vil bruke eget bilde kan du bruke en av karakterene under.
          </Text>
          <View style={styles.avatarSelectorContainer}>
            <SettingsAvatarList onChange={avatarOnChange} newDefaultAvatarId={newDefaultAvatarId} />
          </View>
        </View>
      </ScrollView>
    </BottomSheetModal>
  )
})

export default SettingsAvatarUploadOverlay

const styles = StyleSheet.create({
  avatarSelectorContainer: {
    marginBottom: 50,
  },
})
