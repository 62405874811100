import axios, { AxiosResponse } from 'axios'
import { koplingConfig } from '@/config'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IGetPageByIdReturn = Promise<AxiosResponse>

/*
  Get Squidex "Page" by id
*/
async function getPageById(id: string): IGetPageByIdReturn {
  try {
    const res = await axios({
      url: `${koplingConfig.contentApiUrl}/api/content/app/graphql`,
      method: 'post',
      data: {
        query: `{findPagesContent(id: "${id}") {
          id,
          flatData {
            title,
            content,
            excerpt,
            featuredImage { url },
            featuredYoutube,
            faqs {
              id,
              flatData {
                title,
                content,
                subFaqs {
                  id,
                  flatData {
                    title,
                    content
                  }
                }
              }
            },
            organizations {
              title,
              items {
                id,
                flatData {
                  title,
                  shortName,
                  logo { url }
                }
              }
            },
            users {
              title,
              items
            }
          }
        }}`,
      },
    })
    if (!res.data?.data?.findPagesContent?.flatData) {
      throw new Error('NOT_FOUND')
    }

    res.data = res.data.data.findPagesContent.flatData

    return res
  } catch (error: any) {
    console.log(error.response.data)
    xConsole().error(error, 'getPageById')
    if (error instanceof Error) {
      throw { response: { status: 400 } }
    }
    return error.response as AxiosResponse
  }
}

export default getPageById
