import React, { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, Input, Button } from '@rneui/themed'
import { merge } from 'lodash'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { fetchUser } from '@/slices/userSlice'

// Navigation
import { useNavigation } from '@react-navigation/native'

// Components
import CopyDataButton from '@/components/CopyDataButton'

// APIs
import setProfileInfo from '@/api/auth/setProfileInfo'

// Helpers
import isHelper from '@/plugins/helpers/isHelper'
import { xConsole } from '@/plugins/helpers/xConsole'

// Themes
import theme from '@/config/themes'

// Types
import type { IUser } from '@/types'
type SettingsEditInfoProps = {
  newDefaultAvatarId?: string
}

type IUser_ = IUser & {
  defaultAvatarId?: string
}

const SettingsEditInfo = ({ newDefaultAvatarId }: SettingsEditInfoProps) => {
  const { user, token } = useSelector((state: RootState) => state.user)
  if (!user) {
    return null
  }

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const [userForm, setUserForm] = useState<IUser_ | null>(null)
  const [isAllowed, setIsAllowed] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [validations, setValidations] = useState<Record<string, any>>({
    firstName: {
      regex: '^.{2,}$',
      errorMessage: 'Feltet er obligatorisk.',
      isValid: true,
    },
    lastName: {
      regex: '^.{2,}$',
      errorMessage: 'Feltet er obligatorisk.',
      isValid: true,
    },
    title: {
      regex: '^.{2,}$',
      errorMessage: 'Feltet er obligatorisk.',
      isValid: true,
    },
    description: {
      regex: '^.{1,200}$',
      errorMessage: 'Beskrivelsen må være mellom 1 og 200 tegn.',
      isValid: true,
    },
    defaultAvatarId: {
      regex: '^.{2,}$',
      errorMessage: 'Feltet er obligatorisk.',
      isValid: true,
    },
  })

  useEffect(() => {
    setUserForm(user)
  }, [])

  useEffect(() => {
    onChangeText('defaultAvatarId', newDefaultAvatarId ? newDefaultAvatarId : '') // @TODO
  }, [newDefaultAvatarId])

  const onChangeText = <T extends keyof IUser_>(k: T, v: IUser_[T]) => {
    if (!userForm) {
      return
    }
    const result: IUser_ = { ...userForm, ...{ [k]: v } }
    setUserForm(result)

    if (validations[k] !== undefined && typeof v === 'string') {
      const validation = { ...validations[k] }
      const regex = new RegExp(validation.regex)
      const isValid = regex.test(v)
      setValidations(merge(validations, { [k]: { isValid } }))
    }
    setIsAllowed(JSON.stringify(user) !== JSON.stringify(result)) // Compare prev data and form data
  }

  const submit = async () => {
    try {
      const isValid = !Object.values(validations).some((v) => !v.isValid)
      if (isValid && userForm) {
        setIsLoading(true)
        const res = await setProfileInfo({
          firstName: userForm.firstName || '',
          lastName: userForm.lastName || '',
          description: userForm.description || '',
          title: userForm.title || '',
          defaultAvatarId: userForm.defaultAvatarId || '',
        })
        if (res.status === 200) {
          dispatch(fetchUser())
          setIsAllowed(false)
          navigation.goBack()
          toast.show('Nye profil er lagret.')
        } else {
          toast.show('Beklager, noe gikk galt.')
        }
      }
    } catch (error: any) {
      toast.show('Beklager, noe gikk galt.')
      xConsole().error(error, 'EditInfo.tsx (submit)')
    }
    setIsLoading(false)
  }

  const renderLabel = (title: string) => {
    return <Text style={styles.inputLabel}>{title}</Text>
  }
  return (
    <View style={{ marginBottom: 200 }}>
      <Input
        containerStyle={styles.mainInputContainer}
        inputContainerStyle={styles.inputContainer}
        inputStyle={styles.input}
        errorStyle={styles.inputErrorText}
        label={renderLabel('Fornavn')}
        value={userForm?.firstName || ''}
        onChangeText={(v) => onChangeText('firstName', v)}
        errorMessage={!validations.firstName.isValid ? validations.firstName.errorMessage : ''}
      />
      <Input
        containerStyle={styles.mainInputContainer}
        inputContainerStyle={styles.inputContainer}
        inputStyle={styles.input}
        errorStyle={styles.inputErrorText}
        label={renderLabel('Etternavn')}
        value={userForm?.lastName || ''}
        onChangeText={(v) => onChangeText('lastName', v)}
        errorMessage={!validations.lastName.isValid ? validations.lastName.errorMessage : ''}
      />
      {isHelper(user.roles) && (
        <Input
          containerStyle={styles.mainInputContainer}
          inputContainerStyle={styles.inputContainer}
          inputStyle={styles.input}
          errorStyle={styles.inputErrorText}
          label={renderLabel('Arbeidstittel')}
          value={userForm?.title || ''}
          onChangeText={(v) => onChangeText('title', v)}
          errorMessage={!validations.title.isValid ? validations.title.errorMessage : ''}
        />
      )}
      <Input
        containerStyle={styles.mainInputContainer}
        inputContainerStyle={styles.inputContainer}
        inputStyle={styles.input}
        label={renderLabel('Mobilnummer')}
        value={userForm?.phones.map((v) => v.value).join(', ')}
        disabled
      />
      {isHelper(user.roles) && (
        <Input
          containerStyle={styles.mainInputContainer}
          inputContainerStyle={[styles.inputContainer, { paddingBottom: 10 }]}
          inputStyle={[styles.input, { fontSize: 18 }]}
          errorStyle={styles.inputErrorText}
          label={renderLabel('Beskrivelse')}
          multiline={true}
          value={userForm?.description || ''}
          placeholder={'Skriv en kort tekst om hvem du er og hva du kan hjelpe til med.'}
          onChangeText={(v) => onChangeText('description', v.replace(/(\r\n|\n|\r)/gm, ''))}
          errorMessage={!validations.description.isValid ? validations.description.errorMessage : ''}
        />
      )}
      {isAllowed && (
        <Button
          title="LAGRE PROFIL/ INNSTILLINGER"
          containerStyle={styles.buttonContainer}
          buttonStyle={styles.button}
          titleStyle={styles.buttonTitle}
          disabled={!isAllowed}
          loading={isLoading}
          onPress={submit}
        />
      )}
    </View>
  )
}

export default SettingsEditInfo

const styles = StyleSheet.create({
  mainInputContainer: {
    paddingHorizontal: 0,
  },
  inputLabel: {
    fontFamily: 'FSJoeyMedium',
  },
  inputContainer: {
    borderColor: theme.colors.light,
  },
  input: {
    fontSize: 18,
    minHeight: 35,
  },
  inputErrorText: {
    color: theme.colors.primary,
    fontSize: 9,
    marginLeft: 0,
    height: 10,
  },
  buttonContainer: {
    marginTop: 0,
    marginBottom: 0,
  },
  button: {
    backgroundColor: theme.colors.primary,
  },
  buttonTitle: {
    color: theme.colors.white,
  },
})
