import { useState, useEffect, useRef, useCallback } from 'react'
import { Dimensions, View } from 'react-native'
import axios from 'axios'
import theme from '@/config/themes'

// Components
import SubmitButton from './SubmitButton'
import CountySelector from '@/components/institutions/countySelector'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { fetchInstitutions, fetchCounties } from '@/slices/institutionsSlice'

// Types
import type { ILoginScreenAuth, IInstitutionSelectorRef } from '@/types'
type InstitutionsFormProps = {
  onSuccess: (auth: ILoginScreenAuth) => void
  auth: ILoginScreenAuth
  setSubtitle: (v: string) => void
  resetSubtitle: () => void
}

// Variables
const { height } = Dimensions.get('window')

function CountyForm({ onSuccess, auth, setSubtitle, resetSubtitle }: InstitutionsFormProps) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCounty, setSelectedCounty] = useState(auth?.countyId || '')
  const { institutions, counties } = useSelector((state: RootState) => state.institutions)

  const countySelectorRef = useRef<IInstitutionSelectorRef>(null)

  useEffect(() => {
    dispatch(fetchInstitutions())
    dispatch(fetchCounties())
  }, [])

  useEffect(() => {
    const countyName = findCountyName(selectedCounty)
    if (countyName) {
      setSubtitle(`Du har valgt ${countyName}.`)
    } else {
      resetSubtitle()
    }
  }, [selectedCounty, counties])

  const findCountyName = useCallback(
    (countyId: string) => {
      if (!counties) return ''
      return counties.find((v) => v.countyId === countyId)?.countyName || ''
    },
    [counties]
  )

  const openSelector = () => {
    axios.defaults.headers.common['Authorization'] = auth?.token?.access_token ? `Bearer ${auth.token.access_token}` : ''
    if (!countySelectorRef.current) {
      return
    }

    countySelectorRef.current.setIsActive(true)
  }

  const onSelect = useCallback(
    (active: boolean, id: string) => {
      setSelectedCounty(id)
      if (id !== selectedCounty && auth) {
        auth.institution = '' // Just in case: reset selected institutions for next screens (when user navigate back to this screen)
      }
    },
    [selectedCounty]
  )

  // Submit
  const onSubmit = useCallback(
    async (data: any) => {
      toast.hideAll()
      if (!auth) {
        return
      }
      auth.countyId = selectedCounty

      setIsLoading(true)
      const res = await axios.put(`/api/user/assigncounty?countyId=${auth.countyId}`)
      setIsLoading(false)
      if (res.status === 200) {
        if (data.reference) {
          // Reference data to next page (Example clicked by arrow to do something with next screen)
          auth.reference = data.reference
        }

        if (institutions && institutions.length) {
          if (!institutions.some((v) => v.countyId === selectedCounty)) {
            auth.reference = 'no-institution-in-county'
            await axios.put('/api/user/assigninstitutions', ['none-selected'])
          }

          // Force auto select default institution
          try {
            const autoSelectDefaultInstitutionId = counties.find((v) => v.countyId === selectedCounty)?.autoSelectDefaultInstitutionId
            if (autoSelectDefaultInstitutionId) {
              auth.reference = 'already-selected-institution'
              await axios.put('/api/user/assigninstitutions', [autoSelectDefaultInstitutionId]) // Force select default institution
            }
          } catch (error) {}
        }
        onSuccess(auth)
      } else {
        toast.show('Beklager, noe gikk galt.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      }
    },
    [selectedCounty, auth]
  )

  return (
    <>
      <View>
        <SubmitButton
          title="VELG FYLKE"
          onPress={openSelector}
          loading={false}
          valid={true}
          buttonStyle={{ backgroundColor: theme.colors.teal }}
          titleStyle={{ color: '#fff' }}
          icon={{
            name: 'place',
            type: 'material-icons',
            size: 18,
            color: '#fff',
          }}
        />
      </View>
      <View>
        <SubmitButton title="GÅ VIDERE" onPress={onSubmit} loading={isLoading} valid={!!selectedCounty?.length} disabled={!selectedCounty?.length} />
      </View>
      <CountySelector
        ref={countySelectorRef}
        bottomSheetTopInset={-(height * 0.5)}
        onSelect={onSelect}
        onSubmit={onSubmit}
        defaultSelected={selectedCounty}
      />
    </>
  )
}

export default CountyForm
