// Components
// import { Avatar } from 'react-native-gifted-chat'
import Avatar from '@/components/Avatar'

// Types
import type { AvatarProps, IMessage } from 'react-native-gifted-chat'
type ChatAvatarProps = AvatarProps<IMessage> & {
  isAnonymous: boolean
}

function ChatAvatar(props: ChatAvatarProps) {
  return <Avatar {...props} avatarId={props.currentMessage?.user.avatar || ''} placeholderTitle={props.currentMessage?.user.name} size={28} />
}

export default ChatAvatar
