import { View } from 'react-native'
import { Text } from '@rneui/themed'

// Components
import ScreenSubpageLayout from '../../components/settings/ScreenSubpageLayout'
import SettingsDeleteAccountButton from '../../components/settings/DeleteAccountButton'

const SettingsDeleteAccountScreen = () => {
  return (
    <ScreenSubpageLayout title="Sletting av konto">
      <View style={{ marginTop: 15, marginBottom: 30 }}>
        <Text>Du kontrollerer din data. Ved å trykke «slett konto» vil din profil og tilhørende data bli slettet fra Kopling om 7 dager.</Text>
      </View>
      <View>
        <SettingsDeleteAccountButton />
      </View>
    </ScreenSubpageLayout>
  )
}

export default SettingsDeleteAccountScreen
