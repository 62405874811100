import { StyleSheet, View, StyleProp, ViewStyle, Platform } from 'react-native'
import Markdown, { MarkdownIt } from 'react-native-markdown-display'
import customBlock from 'markdown-it-custom-block'

// Components
import YoutubeComponent from '@/components/contents/Main/components/Youtube'

// Types
type ArticleContentProps = {
  content: string
  containerStyle?: StyleProp<ViewStyle>
  onLinkPress?: (url: string) => boolean
}

const markdownItInstance = MarkdownIt({ typographer: true }).use(customBlock)

const customMarkdownStyle = {
  // The main container
  body: {
    fontFamily: 'FSJoey',
    fontSize: 17,
    color: '#2b2c2b',
    lineHeight: 21,
  },

  // Headings
  heading1: {
    fontFamily: 'FSJoeyBold',
    fontWeight: 'normal',
    fontSize: 45,
    lineHeight: 45,
    marginTop: 20,
  },
  heading2: {
    fontFamily: 'FSJoey',
    fontWeight: 'normal',
    fontSize: 30,
    lineHeight: 33,
    marginTop: 20,
  },
  heading3: {
    fontFamily: 'FSJoeyMedium',
    fontWeight: 'normal',
    fontSize: 22,
    lineHeight: 27,
    marginTop: 20,
  },

  // Horizontal Rule
  hr: {
    backgroundColor: '#e1deda',
    height: 1,
    marginVertical: 20,
  },

  // Blockquotes
  blockquote: {
    backgroundColor: '#fff',
    borderColor: '#e94e24',
    borderLeftWidth: 5,
    marginLeft: 0,
    paddingHorizontal: 10,
  },

  // Emphasis
  strong: {
    fontFamily: 'FSJoeyMedium',
    fontWeight: 'normal',
  },
  em: {
    opacity: 0.75,
  },

  // Links
  link: {
    color: '#e94e24',
    textDecorationLine: 'underline',
  },
  blocklink: {
    flex: 1,
    borderColor: '#000000',
    borderBottomWidth: 1,
  },

  // Images
  image: {
    flex: 1,
  },

  // Text Output
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
}

const ArticleContent = ({ content, onLinkPress, containerStyle }: ArticleContentProps) => {
  const hanndleOnLinkPress = (url: string) => {
    if (Platform.OS === 'web') {
      window.open(url, '_blank')
    }
    if (onLinkPress) {
      return onLinkPress(url)
    }
    return true
  }
  content = content.replace(/@\\\[youtube\\\]/g, '@[youtube]')
  return (
    <View style={[styles.mainContainer, containerStyle]}>
      <Markdown
        style={customMarkdownStyle}
        markdownit={markdownItInstance}
        debugPrintTreer
        onLinkPress={hanndleOnLinkPress}
        rules={{
          custom(node: any) {
            if (node.sourceInfo?.tag === 'youtube') {
              return <YoutubeComponent key={node.key} id={node.sourceInfo.arg} />
            }
            return null
          },
        }}
      >
        {content}
      </Markdown>
    </View>
  )
}

export default ArticleContent

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    paddingHorizontal: 20,
    paddingBottom: 30,
    flexBasis: 'auto', // Web
  },
})
