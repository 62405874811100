import { useEffect, useCallback, useRef } from 'react'
import { AppState } from 'react-native'
import { debounce } from 'lodash'
import axios from 'axios'

// Store
import store from '@/store'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { fetchUser } from '@/slices/userSlice'
import { fetchChannels, fetchTotalUnreadChannels } from '@/slices/channelsSlice'

// Helpers
import checkExpoUpdates from '@/plugins/helpers/checkExpoUpdates'
import { xConsole } from '@/plugins/helpers/xConsole'

// Variables
const mainRunnerRefreshTime = 10 * 1000 // 10 secs
const subRunnerMaximumRefreshTime = 60 * 1000 // 60 secs

// Types
import type { AppStateStatus } from 'react-native'

const DataRefresher = ({ navigation }) => {
  const dispatch = useDispatch()
  const intervalRef = useRef<any>()
  const { user } = useSelector((state: RootState) => state.user)

  const mainRunner = useCallback(async () => {
    try {
      const isAllowedToRun = user && !AppState.currentState.match(/inactive|background/) // If user logged in && the app is active (not minimize)
      if (isAllowedToRun) {
        const { channels } = store.getState()
        if (!channels.isLoading && axios.defaults.headers.common['Authorization']) {
          dispatch(
            fetchChannels({
              page: 1,
              scope: 'id,lastMessageReceivedOn,topic,topicAvatarId,participants,topicDescription,isAnonymous,availability,unread',
            })
          )
          // dispatch(fetchTotalUnreadChannels())
        }

        // Run subRunner() with maximum runtime
        subRunner()
      }
    } catch (error) {
      xConsole().error(error as Error, 'DataRefresher.tsx (mainRunner)')
    }
  }, [user])

  const subRunner = debounce(
    () => {
      try {
        const isAllowedToRun = user && !AppState.currentState.match(/inactive|background/) // If user logged in && the app is active (not minimize)
        if (isAllowedToRun) {
          dispatch(fetchUser())
        }
      } catch (error) {
        xConsole().error(error as Error, 'DataRefresher.tsx (subRunner)')
      }
    },
    subRunnerMaximumRefreshTime,
    { maxWait: 0 }
  )

  const minimizeRunner = () => {
    try {
      const isAllowedToRun = !!user?.userId
      if (isAllowedToRun) {
        // dispatch(fetchTotalUnreadChannels())
      }
    } catch (error) {
      xConsole().error(error as Error, 'DataRefresher.tsx (minimizeRunner)')
    }
  }

  // Do interval
  useEffect(() => {
    intervalRef.current = setInterval(mainRunner, mainRunnerRefreshTime)
    return () => {
      clearInterval(intervalRef.current)
      subRunner.cancel()
    }
  }, [user, intervalRef])

  // On AppState changes
  const onAppStateChanged = (state: AppStateStatus) => {
    if (state === 'active') {
      mainRunner()
      checkExpoUpdates()
    }
    if (state === 'inactive') {
      minimizeRunner()
    }
  }
  useEffect(() => {
    mainRunner()
    checkExpoUpdates()
    const listener = AppState.addEventListener('change', onAppStateChanged)
    return () => {
      listener.remove()
    }
  }, [])

  return null
}

export default DataRefresher
