import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { Text, Icon } from '@rneui/themed'

import theme from '@/config/themes'

// Types
type ContactIconsArgs = {
  openingHours?: string
  staffDescription?: string
  containerStyle?: StyleProp<ViewStyle>
}

const STAFF_DESCRIPTION_ICONS = {
  Fagfolk: {
    type: 'fontisto',
    name: 'doctor',
  },
  'Faste ansatte': {
    type: 'material-icons',
    name: 'work',
  },
  Erfaringsbasert: {
    type: 'font-awesome-5',
    name: 'brain',
  },
  Frivillige: {
    type: 'font-awesome-5',
    name: 'hand-holding-heart',
  },
}

const InfoBox = ({ openingHours, staffDescription, containerStyle }: ContactIconsArgs) => {
  if (!openingHours && !staffDescription) return <></>

  return (
    <View style={[styles.mainContainer, containerStyle]}>
      {openingHours && (
        <View style={styles.openingHoursContainer}>
          <Text style={styles.title}>Åpningstider</Text>
          <Text style={styles.content}>{openingHours}</Text>
        </View>
      )}
      {staffDescription && (
        <View style={[styles.staffDescriptionContainer]}>
          <Text style={styles.title}>Bemanning</Text>
          <Text style={styles.content}>{staffDescription}</Text>
          {STAFF_DESCRIPTION_ICONS[staffDescription] && (
            <Icon
              name={STAFF_DESCRIPTION_ICONS[staffDescription].name}
              type={STAFF_DESCRIPTION_ICONS[staffDescription].type}
              size={30}
              color="#bcb9b4"
              style={{ marginTop: 5 }}
            ></Icon>
          )}
        </View>
      )}
    </View>
  )
}

export default InfoBox

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    paddingVertical: 20,
    backgroundColor: '#fff',
  },
  openingHoursContainer: {
    flex: 0.6,
    paddingHorizontal: 20,
    paddingVertical: 5,
  },
  staffDescriptionContainer: {
    flex: 0.4,
    paddingHorizontal: 20,
    paddingVertical: 5,
    borderLeftWidth: 1,
    borderColor: '#bcb9b4',
    alignItems: 'flex-start',
  },
  title: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 18,
    color: theme.colors.primary,
    marginBottom: 5,
  },
  content: {
    color: '#575656',
  },
})
