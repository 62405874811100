import axios, { AxiosResponse } from 'axios'
import { koplingConfig } from '@/config'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IGetOrganizationsReturn = Promise<AxiosResponse>

/**
 * getOrganizations (Get Squidex contents)
 * @example
 * import getApiOrganizations from '@/api/contents/getOrganizations'
 * // Then use inside a function
 * const res = await getApiOrganizations()
 */

async function getOrganizations(): IGetOrganizationsReturn {
  try {
    const res = await axios({
      url: `${koplingConfig.contentApiUrl}/api/content/app/graphql`,
      method: 'post',
      data: {
        query: `{queryOrganizationsContents(
          orderby: "data/priority/iv desc,created desc"
        ) {
          id,
          flatData {
            title,
            logo { url, pixelWidth, pixelHeight },
            content,
            chatUrl,
            phoneNumber,
            tags,
            categories
          }
        }}`,
      },
    })
    if (!res.data?.data?.queryOrganizationsContents?.length) {
      throw new Error('NOT_FOUND')
    }

    res.data = res.data.data.queryOrganizationsContents

    return res
  } catch (error: any) {
    xConsole().error(error, 'getOrganizations')
    if (error instanceof Error) {
      throw { response: { status: 400 } }
    }
    return error.response as AxiosResponse
  }
}

export default getOrganizations
