import { useRef, useMemo, useCallback, useState, forwardRef, useImperativeHandle } from 'react'
import { View, StyleSheet } from 'react-native'
import { Text, Button } from '@rneui/themed'
import { BottomSheetModal, BottomSheetScrollView, BottomSheetBackdrop } from '@gorhom/bottom-sheet'
import theme from '@/config/themes'

// Components
import ScreenTitle from '@/components/ScreenTitle'
import ListOrganizations from '@/components/contacts/organizations/List'

const CATEGORIES = [
  'Familie og venner',
  'Kropp og sex',
  'Vold og overgrep',
  'Lover og rettigheter',
  'Helse og følelser',
  'Identitet og forelskelse',
  'Fritid og samfunn',
  'Skole / jobb og fremtid',
]

const OrganizationContactsScreen = () => {
  const [categories, setCategories] = useState<Array<string>>([])
  const bottomSheetModalRef = useRef<BottomSheetModal>(null)
  const snapPoints = useMemo(() => [380], [])
  const renderBackdrop = useCallback((props) => <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} />, [])

  const handleDismissModalPress = useCallback(() => {
    bottomSheetModalRef.current?.dismiss()
  }, [])

  const toggleCategory = (v: string) => {
    setCategories((state) => (state.includes(v) ? state.filter((i) => i !== v) : [...state, v]))
  }
  return (
    <View style={{ flex: 1 }}>
      <View>
        <ScreenTitle title="Nasjonale tjenester" />
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: 20, marginBottom: 15 }}>
          <Button
            title="Sorter på tema"
            icon={{ name: 'filter-list', type: 'material-icons', size: 18, color: theme.colors.primary }}
            iconContainerStyle={{ marginRight: 10, marginLeft: 0 }}
            titleStyle={{ color: theme.colors.primary }}
            buttonStyle={{ backgroundColor: 'transparent', justifyContent: 'flex-start', paddingHorizontal: 0, paddingVertical: 0 }}
            containerStyle={{ width: 250 }}
            onPress={() => {
              bottomSheetModalRef.current?.present()
            }}
          />
          {!!categories.length && (
            <Button
              title="Se alle"
              icon={{ name: 'eyeo', type: 'antdesign', size: 19, color: theme.colors.primary }}
              iconContainerStyle={{ marginRight: 10, marginLeft: 0 }}
              titleStyle={{ color: theme.colors.primary }}
              buttonStyle={{ backgroundColor: 'transparent', justifyContent: 'flex-start', paddingHorizontal: 0, paddingVertical: 0 }}
              containerStyle={{ width: 80 }}
              onPress={() => {
                setCategories([])
              }}
            />
          )}
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <ListOrganizations filterCategories={categories} />
      </View>
      <BottomSheetModal ref={bottomSheetModalRef} index={0} snapPoints={snapPoints} backdropComponent={renderBackdrop} stackBehavior={'push'}>
        <View style={styles.bottomSheetHeader}>
          <Button
            title="LUKK"
            onPress={handleDismissModalPress}
            type="clear"
            buttonStyle={[theme._.ButtonStyle.Clear]}
            titleStyle={{ color: theme.colors.primary }}
          />
        </View>
        <View style={styles.tagsContainer}>
          {CATEGORIES.map((v, i) =>
            categories.includes(v) ? (
              <Button
                key={i}
                buttonStyle={[styles.tagButton, { backgroundColor: theme.colors.primary }]}
                titleStyle={[styles.tagTitle, { color: '#fff' }]}
                title={v}
                iconPosition={'right'}
                icon={{ name: 'cross', type: 'entypo', size: 14, color: '#fff' }}
                iconContainerStyle={{ marginRight: 0 }}
                onPress={() => {
                  toggleCategory(v)
                }}
              />
            ) : (
              <Button
                key={i}
                buttonStyle={[styles.tagButton]}
                titleStyle={[styles.tagTitle]}
                title={v}
                onPress={() => {
                  toggleCategory(v)
                }}
              />
            )
          )}
        </View>
      </BottomSheetModal>
    </View>
  )
}

export default OrganizationContactsScreen

const styles = StyleSheet.create({
  contactIconsContainer: {
    marginTop: -30,
    paddingHorizontal: 20,
    justifyContent: 'flex-end',
  },
  infoBoxContainer: {
    marginVertical: 10,
  },
  bottomSheetHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 20,
  },

  tagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    padding: 20,
  },
  tagButton: {
    backgroundColor: 'transparent',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderWidth: 1.5,
    borderColor: theme.colors.primary,
    borderRadius: 100,
  },
  tagTitle: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 14,
    textTransform: 'uppercase',
    color: theme.colors.black,
  },
})
