import { StyleSheet, Platform } from 'react-native'
import { Bubble } from 'react-native-gifted-chat'

// Themes
import theme from '@/config/themes'

// Components
import ChatTicks from './Ticks'
import ChatMessageContactCard from './MessageContactCard'

// Types
import type { IMessage } from '@/types'
import type { BubbleProps } from 'react-native-gifted-chat'
type ChatBubbleProps = BubbleProps<IMessage> & {
  isAnonymous: boolean
  isShowRead: boolean
}

function ChatBubble(props: ChatBubbleProps) {
  const { isAnonymous, isShowRead } = props

  const renderCustomView = (props: any) => {
    if (props.currentMessage) {
      if (props.currentMessage.metaData && props.currentMessage.metaData.customType && props.currentMessage.metaData.customType === 'contactCard') {
        return <ChatMessageContactCard {...props} isAnonymous={isAnonymous} />
      }
    }
    return null
  }

  const renderTicks = () => isShowRead && <ChatTicks {...props} isAnonymous={isAnonymous} />

  return (
    <Bubble
      {...props}
      wrapperStyle={{
        left: {
          ...styles.wrapper,
          ...{
            backgroundColor: 'transparent',
            borderColor: !isAnonymous ? '#e0dbd2' : '#666666',
          },
        },
        right: {
          ...styles.wrapper,
          ...{
            backgroundColor: !isAnonymous ? '#f2efea' : '#1d1d1d',
            borderColor: !isAnonymous ? '#f2efea' : '#1d1d1d',
          },
        },
      }}
      textStyle={{
        left: {
          ...styles.text,
          ...{ color: !isAnonymous ? theme.colors.black : theme.colors.white },
        },
        right: {
          ...styles.text,
          ...{ color: !isAnonymous ? theme.colors.black : theme.colors.white },
        },
      }}
      renderTicks={renderTicks}
      renderCustomView={renderCustomView}
    />
  )
}

export default ChatBubble

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 5,
    marginTop: 10,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderWidth: 2,
  },
  text: {
    fontFamily: 'FSJoey',
    fontSize: 17,
    paddingTop: 2,
    paddingBottom: 0,
    ...Platform.select({
      ios: {
        paddingBottom: 0,
      },
      android: {
        paddingBottom: 3,
      },
      web: {
        flexDirection: 'column',
      },
    }),
    color: theme.colors.black,
  },
})
