import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { Icon, Text } from '@rneui/themed'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import theme from '@/config/themes'

// Navigation
import { useNavigation } from '@react-navigation/native'

// Types
type ScreenTitleProps = {
  title?: string
  goBack?: boolean
}
const ScreenTitle = ({ title, goBack }: ScreenTitleProps) => {
  const navigation = useNavigation()
  const insets = useSafeAreaInsets()
  return (
    <View style={[styles.mainContainer, { paddingTop: insets.top + 20 }]}>
      {goBack && (
        <TouchableOpacity
          onPress={() => {
            navigation.goBack()
          }}
          style={styles.backButton}
        >
          <Icon type="entypo" name="chevron-left" color={theme.colors.black} size={38} />
        </TouchableOpacity>
      )}
      {title && (
        <Text h3 h3Style={{ fontFamily: 'FSJoeyBold' }}>
          {title}
        </Text>
      )}
    </View>
  )
}

export default ScreenTitle

const styles = StyleSheet.create({
  mainContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 10,
    marginBottom: 1,
    backgroundColor: 'rgba(242,239,234,0.6)',
    zIndex: 1,
  },
  backButton: {
    padding: 10,
    marginLeft: -20,
    marginTop: -15,
    marginBottom: -15,
  },
})
