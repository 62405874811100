import axios, { AxiosResponse } from 'axios'
import { koplingConfig } from '@/config'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IGetOrganizationByIdReturn = Promise<AxiosResponse>

/*
  Get Squidex "Organization" by id
*/
async function getOrganizationById(id: string): IGetOrganizationByIdReturn {
  try {
    const res = await axios({
      url: `${koplingConfig.contentApiUrl}/api/content/app/graphql`,
      method: 'post',
      data: {
        query: `{findOrganizationsContent(id: "${id}") {
          id
          flatData {
            title,
            logo { url, pixelWidth, pixelHeight },
            featuredImage { url },
            content,
            content2,
            chatUrl,
            phoneNumber,
            openingHours,
            staffDescription,
            tags,
            pages { id },
            organizationId
          }
        }}`,
      },
    })
    if (!res.data?.data?.findOrganizationsContent?.flatData) {
      throw new Error('NOT_FOUND')
    }

    res.data = res.data.data.findOrganizationsContent.flatData

    return res
  } catch (error: any) {
    xConsole().error(error, 'getOrganizationById')
    if (error instanceof Error) {
      throw { response: { status: 400 } }
    }
    return error.response as AxiosResponse
  }
}

export default getOrganizationById
