import { View } from 'react-native'
import { Text } from '@rneui/themed'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Components
import ScreenTitle from '@/components/ScreenTitle'
import ListContacts from '@/components/contacts/institutions/List'

const InstitutionContactsScreen = () => {
  const { user } = useSelector((state: RootState) => state.user)
  if (!user) {
    return
  }
  return (
    <View style={{ flex: 1 }}>
      <View>
        <ScreenTitle title="Dine hjelpere" />
        <Text style={{ marginHorizontal: 20, fontSize: 20 }}>Her finner du dine hjelpere. Send oss en melding om du lurer på noe.</Text>
      </View>
      <View style={{ flex: 1 }}>
        <ListContacts />
      </View>
    </View>
  )
}

export default InstitutionContactsScreen
