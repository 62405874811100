import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { koplingConfig } from '@/config'

import { REHYDRATE } from 'redux-persist'

import moment from 'moment'

// Types
import type { IChannel, IMessage, IChannelCustomSystemMessage } from '@/types'
import type { RootState } from '@/store/rootReducer'

// Types: Channels
type IGetChannelsValueResponse = IChannel[]
interface IGetChannelsResponse {
  items: IGetChannelsValueResponse
}
type IGetChannelsArgs = {
  page?: number
  perPage?: number
  scope?: string
}

type IAddChannelValueResponse = IChannel
type IAddChannelResponse = IChannel
type IAddChannelArgs = {
  participant: string
  isAnonymous: boolean
  reference: object
  firstMessage: string
  customSystemMessage?: IChannelCustomSystemMessage
  onSuccess: Function
  onError: Function
}

// Types: Messages
type IGetMessagesValueResponse = IMessage[]
type IGetMessagesResponse = IMessage[]
type IGetMessagesArgs = {
  id: string
  limit?: number
  before?: string | null
}

type IAddMessageValueResponse = number
type IAddMessageResponse = number
type IAddMessageArgs = {
  id: string
  content: string
  metaData?: object
}

type IReadReceipt = {
  chatMessageId: string
  readOn: string
  senderDisplayName: string
  senderUserID: string
}
type IGetReadReceiptsValueResponse = IReadReceipt[]
type IGetReadReceiptsResponse = IReadReceipt[]

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: fetchBaseQuery({
    baseUrl: koplingConfig.apiUrl,
    prepareHeaders: (headers, { getState, extra }) => {
      const { token } = (getState() as RootState).user
      if (token) {
        headers.set('authorization', `Bearer ${token.access_token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Channels', 'Messages'],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE && action.payload) {
      return action.payload[reducerPath]
    }
  },
  keepUnusedDataFor: 86400, // 1 day

  endpoints: (builder) => ({
    getChannels: builder.query<IGetChannelsValueResponse, IGetChannelsArgs | void>({
      query: (args) => {
        const defaults: IGetChannelsArgs = {
          page: 1,
          perPage: 100,
          scope: 'id,lastMessageReceivedOn',
        }
        const options: IGetChannelsArgs = { ...defaults, ...args }
        return `/api/channel/list3?page=${options.page}&per_page=${options.perPage}&scope=${options.scope}`
      },
      transformResponse: (result: IGetChannelsResponse) => result.items ?? [],
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Channels', id } as const)), { type: 'Channels', id: 'LIST' }]
          : [{ type: 'Channels', id: 'LIST' }],
    }),
    getChannel: builder.query<IChannel, string>({
      query(id) {
        return { url: `/api/channel/${id}` }
      },
      transformResponse: (result: IChannel) => result ?? {},
      providesTags: (result, error, id) => [
        { type: 'Channels', id },
        { type: 'Messages', id },
      ],
    }),
    addChannel: builder.mutation<IAddChannelValueResponse, IAddChannelArgs>({
      query: (args) => {
        return {
          url: `/api/channel/create`,
          method: 'POST',
          body: {
            participants: [args.participant],
            isAnonymous: args.isAnonymous,
            reference: args.reference,
          },
        }
      },
      transformResponse: (result: IAddChannelResponse) => result,
      async onCacheEntryAdded(args, { dispatch, cacheDataLoaded }) {
        try {
          const { data } = await cacheDataLoaded

          // Send custom system message
          if (args.customSystemMessage) {
            dispatch(
              chatApi.endpoints.addMessage.initiate({
                id: data.id,
                content: 'Referansemelding…', // Just for showing last message
                metaData: { customType: args.customSystemMessage.type, message: args.customSystemMessage.message },
              })
            )
          }
          // Send first message
          dispatch(chatApi.endpoints.addMessage.initiate({ id: data.id, content: args.firstMessage }))

          await dispatch(chatApi.endpoints.getChannel.initiate(data.id))
          args.onSuccess(data)
        } catch {
          args.onError()
        }
      },
      transformErrorResponse: (response, meta, args) => {
        args.onError()
        return response
      },
      invalidatesTags: (result, error, v) => [
        { type: 'Channels', id: 'LIST' },
        { type: 'Messages', id: 'LIST' },
      ],
    }),
    deleteChannel: builder.mutation<boolean, string>({
      query(id) {
        return {
          url: `/api/channel/${id}/leave`,
          method: 'POST',
        }
      },
      invalidatesTags: (result, error, id) => [
        { type: 'Channels', id },
        { type: 'Messages', id },
      ],
    }),
    getMessages: builder.query<IGetMessagesValueResponse, IGetMessagesArgs>({
      query: (args) => {
        const defaults: IGetMessagesArgs = {
          id: '',
          limit: 200,
          before: '',
        }
        const options: IGetMessagesArgs = { ...defaults, ...args }
        return `/api/channel/${options.id}/message?limit=${options.limit}&before=${options.before}`
      },
      transformResponse: (result: IGetMessagesResponse) => {
        const formattedResult = result.map((v: any) => {
          // Format data to react-native-gifted-chat objects
          const formatted = {
            _id: v.messageId,
            text: v.content,
            createdAt: v.unixTimeStamp * 1000,
            user: {
              _id: v.senderUserId,
              name: v.senderDisplayName,
              avatar: v.avatarId,
            },
            metaData: v.metaData,
            system: v.senderDisplayName === 'system',
          } as IMessage

          if (formatted.system) {
            if (v.type === 'participantAdded') {
              formatted.text = `${v.metaData.user?.displayName || 'Noen'} ble med i samtalen.`
            } else if (v.type === 'participantRemoved') {
              formatted.text = `${v.metaData.user?.displayName || 'Noen'} forlot samtalen.`
            }

            formatted.metaData.customType = v.type
          }

          if (formatted.metaData && formatted.metaData.customType) {
            formatted.text = ''

            if (formatted.metaData.customType.includes('SystemMessage')) {
              formatted.text = formatted.metaData.message
              formatted.system = true
            }
          }

          return formatted
        })
        return formattedResult ?? []
      },

      providesTags: (result, error, v) => {
        return [
          { type: 'Messages', id: v.id },
          { type: 'Channels', id: v.id },
        ]
      },
    }),
    addMessage: builder.mutation<IAddMessageValueResponse, IAddMessageArgs>({
      query: (args) => {
        return {
          url: `/api/channel/${args.id}/message`,
          method: 'POST',
          body: {
            content: args.content,
            metaData: args.metaData,
          },
        }
      },
      transformResponse: (result: IAddMessageResponse) => result ?? 0,
      invalidatesTags: (result, error, { id }) => [
        { type: 'Channels', id: 'LIST' },
        { type: 'Channels', id },
        { type: 'Messages', id: 'LIST' },
      ],
    }),

    getTotalUnreadChannelsDev: builder.query<number, void>({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        try {
          let result = 0
          const state = api.getState() as RootState
          const all = state.chatApi.queries['getTotalUnreadChannelsAll(undefined)']
          const totalUnreadAll = (all?.data as number) ?? 0
          const shouldFetch = !all || moment().diff(moment(all.fulfilledTimeStamp), 'second') > 1
          if (shouldFetch) {
            const promise = api.dispatch(chatApi.endpoints.getTotalUnreadChannels.initiate(undefined))
            const { refetch } = promise
            console.log(promise)
            // refetch()
          }

          const res = await baseQuery({
            url: `/api/channel/totalunreadchannels?startTime=${moment().subtract(1, 'minutes').toISOString()}`,
          })
          const totalUnreadNew = (res.data as number) ?? 0
          result = totalUnreadAll > totalUnreadNew ? totalUnreadAll : totalUnreadNew
          console.log(totalUnreadAll, totalUnreadNew)
          return { data: result }
        } catch {
          return { status: 400, data: 0 }
        }
      },
    }),
    getTotalUnreadChannels: builder.query<number, void>({
      query() {
        const startTime = moment().subtract(30, 'days').toISOString()
        return { url: `/api/channel/totalunreadchannels?startTime=${startTime}` }
      },
      transformResponse: (result: number) => {
        return result ?? 0
      },
    }),
    getReadReceipts: builder.query<IGetReadReceiptsValueResponse, string>({
      query(id) {
        return { url: `/api/channel/${id}/readreceipts` }
      },
      transformResponse: (result: IGetReadReceiptsResponse) => result ?? [],
    }),
  }),
})

export default chatApi
