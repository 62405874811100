import { useState, useEffect, useCallback } from 'react'
import { StyleSheet, View, RefreshControl, ActivityIndicator, Dimensions } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ScrollView } from 'react-native-gesture-handler'
import axios from 'axios'

import TabMenus from '@/components/TabMenus'

// APIs
import getApiOrganizationById from '@/api/contents/getOrganizationById'

// Components

import ArticleContent from '@/components/contents/Main/ArticleContent'
import TopNavbar from '@/components/contacts/organizations/TopNavbar'
import FeaturedImage from '@/components/contacts/organizations/FeaturedImage'
import Title from '@/components/contacts/organizations/Title'
import Tags from '@/components/contacts/organizations/Tags'
import ContactIcons from '@/components/contacts/organizations/ContactIcons'
import InfoBox from '@/components/contacts/organizations/InfoBox'

import QueryPages from '@/components/contacts/organizations/QueryPages'

// Theme
import theme from '@/config/themes'

// Variables
const { height } = Dimensions.get('window')

// Types
import type { NativeStackScreenProps } from '@react-navigation/native-stack'
import type { RootNavigatorParamList } from '@/types'
type OrganizationContactsPageScreenProps = NativeStackScreenProps<RootNavigatorParamList, 'OrganizationContactsPageScreen'>

const OrganizationContactsPageScreen = ({ route }: OrganizationContactsPageScreenProps) => {
  const { id } = route.params
  const [organizationId, setOrganizationId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [content, setContent] = useState<Record<string, any>>({})

  useEffect(() => {
    getContent()
  }, [])

  const getContent = useCallback(async () => {
    try {
      setIsLoading(true)
      const res = await getApiOrganizationById(id)
      if (res.data.organizationId) {
        axios.get(`/api/organization/${res.data.organizationId}`)
        setOrganizationId(res.data.organizationId)
      }
      setContent(res.data)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }, [id])

  const refreshContent = useCallback(async () => {
    try {
      setIsRefreshing(true)
      const res = await getApiOrganizationById(id)
      await new Promise((r) => setTimeout(r, 1000))
      setContent(res.data)
    } catch (error) {
    } finally {
      setIsRefreshing(false)
    }
  }, [id])

  const onLinkPress = useCallback(
    (url: string) => {
      if (organizationId) {
        // Analytic
        axios.put(`/api/organization/${organizationId}/link?url=${url}`)
      }
      return true
    },
    [organizationId]
  )

  return (
    <SafeAreaView edges={['bottom', 'left', 'right']} style={{ flexDirection: 'column', flex: 1 }}>
      <TopNavbar />

      <ScrollView
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={refreshContent} />}
        contentContainerStyle={{ paddingBottom: 20 }}
      >
        {!isLoading ? (
          <>
            <FeaturedImage
              src={content.featuredImage && content.featuredImage.length ? content.featuredImage[0].url : ''}
              hasVideo={!!content.featuredYoutube}
            />
            <ContactIcons
              chatUrl={content.chatUrl}
              phoneNumber={content.phoneNumber}
              organizationId={content.organizationId}
              type="large"
              containerStyle={styles.contactIconsContainer}
            />
            {!!content.tags && (
              <Tags
                tags={content.tags}
                containerStyle={{ paddingHorizontal: 20, marginVertical: 20 }}
                tagsContainerStyle={{ backgroundColor: '#e3e1dc' }}
              />
            )}

            {!!content.title && <Title title={content.title} />}
            {!!content.content && <ArticleContent content={content.content} onLinkPress={onLinkPress} />}
            <InfoBox openingHours={content.openingHours} staffDescription={content.staffDescription} containerStyle={styles.infoBoxContainer} />
            {!!content.content2 && <ArticleContent content={content.content2} />}
            {!!content.pages && <QueryPages ids={content.pages} />}
          </>
        ) : (
          <View style={{ justifyContent: 'center', flex: 1, height: height }}>
            <ActivityIndicator style={{ margin: 10 }} color={theme.colors.black} />
          </View>
        )}
      </ScrollView>

      <TabMenus />
    </SafeAreaView>
  )
}

export default OrganizationContactsPageScreen

const styles = StyleSheet.create({
  contactIconsContainer: {
    marginTop: -30,
    paddingHorizontal: 20,
    justifyContent: 'flex-end',
  },
  infoBoxContainer: {
    marginVertical: 10,
  },
})
