import { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react'
import { Platform } from 'react-native'
import * as Notifications from 'expo-notifications'
import * as Application from 'expo-application'
import * as Device from 'expo-device'
import * as TaskManager from 'expo-task-manager'

import { Audio } from 'expo-av'

// APIs
import setApiNotificationToken from '@/api/auth/setNotificationToken'
import { chatApi } from '@/api/chatApi'

// Store
import store from '@/store'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import type { NavigationContainerRefWithCurrent } from '@react-navigation/native'
import type { RootNavigatorParamList } from '@/types'
import { Routes } from '@/config/routes'
import type { Subscription } from 'expo-modules-core'
type NotificationsContextData = {
  initNotifications: Function
  getNotificationData: Function
  promptRequestPermissions: Function
}
type NotificationsProps = {
  children: React.ReactNode
  navigation: NavigationContainerRefWithCurrent<RootNavigatorParamList>
}

// Variables
const NotificationsContext = createContext<NotificationsContextData>({} as NotificationsContextData)

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
})

// Background notification task
const BACKGROUND_NOTIFICATION_TASK = 'BACKGROUND-NOTIFICATION-TASK'
TaskManager.defineTask(BACKGROUND_NOTIFICATION_TASK, ({ data }: any) => {
  try {
    if (data?.notification?.data?.body) {
      const content = JSON.parse(data?.notification?.data?.body)
      if (typeof content === 'object') {
        if (content.totalunreadchannels) {
          store.dispatch(
            chatApi.util.updateQueryData('getTotalUnreadChannels', undefined, (draft) => {
              return content.totalunreadchannels
            })
          )
        }
      }
    }
  } catch (error) {}
})

const NotificationsProvider: React.FC<NotificationsProps> = ({ children, navigation }) => {
  if (Platform.OS === 'web') {
    return <>{children}</>
  }

  const { user } = useSelector((state: RootState) => state.user)
  const [notificationToken, setNotificationToken] = useState<string | null>(null)
  const [deviceId, setDeviceId] = useState<string | null>(null)
  const notificationListener = useRef<Subscription>()
  const responseListener = useRef<Subscription>()

  const { data: totalUnread } = chatApi.endpoints.getTotalUnreadChannels.useQuery(undefined, {
    skip: !user?.userId,
    refetchOnMountOrArgChange: true,
  })

  const initNotifications = async () => {
    if (Platform.OS === 'web') {
      return
    }
    // Device Id
    const deviceId = await getDeviceId()
    setDeviceId(deviceId)
    // Notification token
    const notificationToken = await getNotificationToken()
    setNotificationToken(notificationToken)

    try {
      if (deviceId && notificationToken) {
        await setApiNotificationToken({
          notificationToken: notificationToken,
          deviceId: deviceId,
          os: Platform.OS,
        })
      }
    } catch (error) {}
  }

  useEffect(() => {
    Audio.setAudioModeAsync({
      staysActiveInBackground: true,
      shouldDuckAndroid: false,
      playThroughEarpieceAndroid: false,
      playsInSilentModeIOS: true,
    })
  }, [])
  const playNotificationSound = async () => {
    try {
      const { sound } = await Audio.Sound.createAsync(require('@/assets/sounds/notification.mp3'))
      await sound.playAsync()
      setTimeout(async () => {
        await sound.unloadAsync()
      }, 2500)
    } catch (error) {}
  }

  useEffect(() => {
    if (user?.userId) {
      initNotifications()
    }
  }, [user?.userId])

  useEffect(() => {
    notificationListener.current = Notifications.addNotificationReceivedListener(async (response) => {
      // Show inApp notification
      try {
        if (response.request?.content?.body && response.request?.content?.data?.channelId) {
          toast.hideAll()
          toast.show(response.request.content.body, {
            type: 'newMessage',
            data: {
              channelId: response.request.content.data.channelId,
              avatarId: response.request?.content?.data?.avatarId,
              isAnonymous: response.request?.content?.data?.isAnonymous,
            },
          })
          playNotificationSound()
        }
      } catch (error) {}

      // Remove silient notification
      try {
        if (response.request?.content?.data?.totalunreadchannels) {
          await Notifications.dismissNotificationAsync(response.request.identifier)
        }
      } catch (error) {}

      // Clear notifications
      try {
        Notifications.getPresentedNotificationsAsync().then((notifications) => {
          let gotFirstNotification = false
          for (const v of notifications) {
            if (v.request?.content?.data?.totalunreadchannels) {
              Notifications.dismissNotificationAsync(v.request.identifier) // Remove silient notification (double check)
              continue
            }
            if (v.request?.content?.body && v.request?.content?.data?.channelId && !gotFirstNotification) {
              gotFirstNotification = true
              continue
            }
            Notifications.dismissNotificationAsync(v.request.identifier)
          }
        })
      } catch (error) {}
    })

    // Trigger when open the app with notifications
    responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
      // Auto navigate
      let x = 0
      let intervalID = setInterval(() => {
        if (navigation.isReady()) {
          navigation.navigate(Routes.Channels)
          clearInterval(intervalID)
        }
        if (++x === 20) {
          clearInterval(intervalID)
        }
      }, 100)
    })

    return () => {
      if (notificationListener.current) {
        Notifications.removeNotificationSubscription(notificationListener.current)
      }
      if (responseListener.current) {
        Notifications.removeNotificationSubscription(responseListener.current)
      }
    }
  }, [])

  const getNotificationData = useCallback(() => {
    return { notificationToken, deviceId }
  }, [notificationToken, deviceId])

  // Set total notification badges
  useEffect(() => {
    try {
      if (totalUnread === 0) {
        Notifications.setBadgeCountAsync(0)
        Notifications.dismissAllNotificationsAsync()
      } else if (totalUnread && totalUnread > 0) {
        Notifications.setBadgeCountAsync(totalUnread)
      }
    } catch (error) {}
  }, [totalUnread])

  // Remove Background notification tasks
  useEffect(() => {
    try {
      if (Platform.OS === 'web') return

      // Remove old task
      TaskManager.isTaskRegisteredAsync('BACKGROUND-FETCH-TASK').then((isRegistered) => {
        if (isRegistered) TaskManager.unregisterTaskAsync('BACKGROUND-FETCH-TASK')
      })

      if (user?.userId) {
        // Register BACKGROUND_NOTIFICATION_TASK
        Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK)
      } else {
        TaskManager.unregisterAllTasksAsync()
      }
    } catch (error) {}
  }, [user?.userId])

  // Return Provider
  return (
    <NotificationsContext.Provider value={{ initNotifications, getNotificationData, promptRequestPermissions }}>
      {children}
    </NotificationsContext.Provider>
  )
}

function useNotifications(): NotificationsContextData {
  const context = useContext(NotificationsContext)
  if (!context) {
    throw new Error('useNotifications must be used within an NotificationsProvider')
  }
  return context
}

export { NotificationsContext, NotificationsProvider, useNotifications }

async function getDeviceId() {
  let id: string | null = null
  try {
    if (Platform.OS === 'android') {
      id = Application.getAndroidId() || ''
    } else if (Platform.OS === 'ios') {
      id = (await Application.getIosIdForVendorAsync()) || ''
    }
  } catch (error: any) {
    xConsole().error(error, 'getDeviceId')
  } finally {
    return id
  }
}

async function getNotificationToken() {
  let token: string | null = null
  try {
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#e94e24',
      })
    }

    if (Device.isDevice || __DEV__) {
      const { status: existingStatus, ios } = await Notifications.getPermissionsAsync()
      let finalStatus = existingStatus
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync({ ios: { allowBadge: true, allowSound: true } })
        finalStatus = status
      }
      if (finalStatus !== 'granted') {
        return null
      }

      token = (await Notifications.getExpoPushTokenAsync()).data
      // token = (await Notifications.getExpoPushTokenAsync({ projectId: '9a3004fb-09c1-4e42-9350-3ca3bcf941db' })).data
    }
  } catch (error: any) {
    xConsole().error(error, 'registerNotifications')
  } finally {
    return token
  }
}

async function promptRequestPermissions() {
  const { status } = await Notifications.requestPermissionsAsync({ ios: { allowBadge: true, allowSound: true } })
  return status
}
