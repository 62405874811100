import { useState, useEffect, useCallback, useRef } from 'react'
import { Keyboard, Platform, Linking, Text } from 'react-native'
import { GiftedChat } from 'react-native-gifted-chat'
import { SafeAreaView } from 'react-native-safe-area-context'
import { getBottomSpace } from 'react-native-iphone-x-helper'
import * as Clipboard from 'expo-clipboard'
import 'dayjs/locale/nb'
import moment from 'moment'
import { debounce } from 'lodash'

// ACS
import { useACS } from '@/contexts/acs'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Components
import ChatEmpty from '@/components/chat/ChatEmpty'
import ChatAvatar from '@/components/chat/Avatar'
import ChatMessage from '@/components/chat/Message'
import ChatMessageText from '@/components/chat/MessageText'
import ChatSystemMessage from '@/components/chat/SystemMessage'
import ChatDay from '@/components/chat/Day'
import ChatBubble from '@/components/chat/Bubble'
import ChatInput from '@/components/chat/Input'
import ChatInputSend from '@/components/chat/InputSend'
import ChatInputActions from '@/components/chat/actions/InputActions'
import ChatComposer from '@/components/chat/Composer'
import ChatFooter from '@/components/chat/Footer'

// APIs
import { chatApi } from '@/api/chatApi'

// Actions
import ChatInputActionShareContacts from '@/components/chat/actions/shareContacts'

// Helpers
import isHelper from '@/plugins/helpers/isHelper'
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import type { RootNavigatorParamList, IMessage, IChannel, IShareContactsRef } from '@/types'
import type { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Routes } from '@/config/routes'

type ChatViewProps = NativeStackScreenProps<RootNavigatorParamList, Routes.Chat>

function ChatView(props: ChatViewProps) {
  const dispatch = useDispatch()
  const { item, create, reference, customSystemMessage } = props.route.params
  const { navigation } = props
  const { getChatClient, typingIndicators } = useACS()

  /* ==============================================================
  :: User :::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  const { user } = useSelector((state: RootState) => state.user)

  /* ==============================================================
  :: APIs :::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  const { data: channelData, refetch: refetchChannel } = chatApi.endpoints.getChannel.useQuery(item.id, {
    skip: !!create,
    refetchOnMountOrArgChange: true,
  })
  const { data: messagesData } = chatApi.endpoints.getMessages.useQuery(
    { id: item.id },
    {
      pollingInterval: 10000,
      skip: !!create,
      refetchOnMountOrArgChange: true,
    }
  )
  const { data: readReceiptsData } = chatApi.endpoints.getReadReceipts.useQuery(item.id, {
    pollingInterval: 1000,
    skip: !!create,
    refetchOnMountOrArgChange: true,
  })

  /* ==============================================================
  :: Messages :::::::::::::::::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  const [allMessages, setAllMessages] = useState<IMessage[]>([])
  const [moreMessagesData, setMoreMessagesData] = useState<IMessage[]>([])

  const [lastReadMessageId, setLastReadMessageId] = useState('')
  const [shouldUpdateMessage, setShouldUpdateMessage] = useState(false)

  useEffect(() => {
    setAllMessages(messagesData ? [...messagesData, ...moreMessagesData] : [])
  }, [messagesData, moreMessagesData])

  useEffect(() => {
    if (Platform.OS === 'web') {
      const gcLoadingContaineEl = document.querySelectorAll('[data-testid="GC_LOADING_CONTAINER"]')[0] as HTMLElement
      if (gcLoadingContaineEl) {
        gcLoadingContaineEl.style.display = 'none'
        setTimeout(() => {
          gcLoadingContaineEl.style.display = 'flex'
        }, 500)
      }
    }
  }, [])

  /* Function: onSend callback (Check if create or just send message) */
  const onSend = useCallback(
    async (messages = []) => {
      if (create) {
        Keyboard.dismiss()
        const toastLoading = toast.show(`Starter${item.isAnonymous ? ' anonym' : ''} samtale med ${item.topic}`, {
          type: 'center',
          duration: 1000000,
          swipeEnabled: false,
          data: { isDark: item.isAnonymous, iconType: 'feather', iconName: 'loader', isAnimate: true },
        })
        dispatch(
          chatApi.endpoints.addChannel.initiate({
            participant: create,
            isAnonymous: item.isAnonymous,
            reference: reference as object,
            firstMessage: messages[0].text,
            customSystemMessage: customSystemMessage,
            onSuccess(newChannel: IChannel) {
              toast.hide(toastLoading)
              navigation.setParams({
                item: newChannel,
                create: undefined,
              })
            },
            onError() {
              toast.hide(toastLoading)
              toast.show(`Beklager, noe gikk galt, vennligst prøv igjen eller kontakt Elevtjenesten`, {
                type: 'center',
                duration: 3000,
                swipeEnabled: false,
                data: { isDark: item.isAnonymous, iconType: 'feather', iconName: 'x', isAnimate: false },
              })
            },
          })
        )

        //
      } else {
        if (!item.id) {
          return
        }
        sendMessage(messages)
      }
    },
    [create]
  )
  /* Function: Send message */
  const sendMessage = async (messages: IMessage[]) => {
    const currentMessages = { ...messages[0] }
    const channelId = channelData?.id
    if (!channelId) {
      return
    }
    dispatch(chatApi.endpoints.addMessage.initiate({ id: channelId, content: currentMessages.text }))
  }

  /* ==============================================================
  :: Pagination :::::::::::::::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  const [triggerGetMoreMessages] = chatApi.endpoints.getMessages.useLazyQuery()

  /* Function: Load earlier messages */
  const onLoadEarlier = useCallback(async () => {
    try {
      let lastMessageId = ''
      if (moreMessagesData.length) {
        lastMessageId = moreMessagesData[moreMessagesData.length - 1]._id
      } else if (messagesData) {
        lastMessageId = messagesData[messagesData.length - 1]._id
      }
      if (lastMessageId) {
        triggerGetMoreMessages({ id: item.id, before: lastMessageId })
          .unwrap()
          .then((payload) => {
            setMoreMessagesData((prev) => {
              const merged = [...(prev ?? []), ...payload]
              const filtered = merged.filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i)
              return filtered
            })
          })
      }
    } catch (error) {}
  }, [messagesData, moreMessagesData, item])

  /* Last read messages */
  useEffect(() => {
    if (!readReceiptsData || !user?.userId) return
    const readReceipts = readReceiptsData.filter((v) => v.senderUserID !== user?.userId)
    if (readReceipts) {
      const readReceipt = readReceipts[0]
      // setShouldUpdateMessage
      setLastReadMessageId((prev) => {
        if (readReceipt && readReceipt.chatMessageId !== prev) {
          setShouldUpdateMessage(true)
        }
        return readReceipt?.chatMessageId || ''
      })
    }
  }, [readReceiptsData, user?.userId])

  /* Typing */
  const [isTyping, setIsTyping] = useState('')
  const onInputTextChanged = debounce(async (text: string) => {
    try {
      const v = text.trim()
      if (v.length > 0 && item.id) {
        const chatClient = await getChatClient()
        const displayName = channelData?.participants?.find((v) => v.isLoggedInUser)?.displayName
        if (chatClient && displayName) {
          const thread = chatClient.getChatThreadClient(item.id)
          thread.sendTypingNotification({ senderDisplayName: displayName })
        }
      }
    } catch {}
  }, 1000)

  useEffect(() => {
    let timer
    try {
      const displayName = channelData?.participants?.find((v) => v.isLoggedInUser)?.displayName
      const typingIndicator = typingIndicators.find((v) => v.threadId === item.id && v.senderDisplayName !== displayName)
      if (typingIndicator) {
        const diff = moment().diff(moment(typingIndicator.receivedOn), 'seconds')
        if (diff <= 10) {
          setIsTyping(typingIndicator.senderDisplayName)
          timer = setTimeout(() => {
            setIsTyping('')
          }, 5000)
        }
      }
    } catch (error) {}
    return () => clearTimeout(timer)
  }, [typingIndicators, item, channelData])

  /* ==============================================================
  :: GiftedChat's functions :::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  /* Function: On long press message, show actions */
  const onLongPress = (context: any, message: IMessage) => {
    const options = ['Kopier meldingen', 'Avbryt']
    const cancelButtonIndex = options.length - 1
    context.actionSheet().showActionSheetWithOptions({ options, cancelButtonIndex }, (i: number) => {
      switch (i) {
        case 0:
          Clipboard.setString(message.text)
          break
      }
    })
  }

  /* ==============================================================
  :: GiftedChat's components ::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  const renderAvatar = (props: any) => <ChatAvatar {...props} isAnonymous={channelData?.isAnonymous} />
  const renderMessage = (props: any) => <ChatMessage {...props} />
  const renderMessageText = (props: any) => <ChatMessageText {...props} isAnonymous={channelData?.isAnonymous} />
  const renderBubble = (props: any) => (
    <ChatBubble {...props} isAnonymous={channelData?.isAnonymous} isShowRead={props.currentMessage._id === lastReadMessageId} />
  )

  const renderSystemMessage = (props: any) => <ChatSystemMessage {...props} isAnonymous={channelData?.isAnonymous} />
  const renderDay = (props: any) => <ChatDay {...props} isAnonymous={channelData?.isAnonymous} />
  const renderSend = (props: any) => <ChatInputSend {...props} />
  const renderInputToolbar = (props: any) => {
    if (channelData?.participants && channelData?.participants?.length <= 1) {
      return null
    }
    return <ChatInput {...props} isAnonymous={channelData?.isAnonymous || item.isAnonymous} />
  }
  const renderComposer = (props: any) => <ChatComposer {...props} isAnonymous={channelData?.isAnonymous || item.isAnonymous} />
  const renderActions = () => {
    if (!channelData || !user || !isHelper(user.roles)) {
      return null
    }
    return <ChatInputActions {...props} isAnonymous={channelData?.isAnonymous || item.isAnonymous} trigger={triggerAction} />
  }

  /* ==============================================================
  :: Input actions ::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  /* Function: Main trigger action switcher */
  const triggerAction = (actionName: string) => {
    switch (actionName) {
      case 'shareContacts':
        shareContactsActions()
        break
      default:
        xConsole().log(`Cannot find ${actionName}'s action`)
    }
  }
  /* Function: Share contacts */
  const shareContactsRef = useRef<IShareContactsRef>(null)
  const shareContactsActions = () => {
    if (!shareContactsRef.current) {
      return
    }
    shareContactsRef.current.setIsActive(true)
  }

  const handleUrlPress = (url: string) => {
    if (Platform.OS !== 'web') {
      Linking.openURL(url)
    } else {
      window.open(url, '_blank')
    }
  }

  /* On leave screen, refetch the channel data again */
  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      if (!create) {
        refetchChannel()
      }
    })
    return unsubscribe
  }, [navigation])

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['bottom']}>
      <GiftedChat
        messages={allMessages}
        onSend={(messages) => onSend(messages)}
        user={{ _id: channelData?.participants?.find((v) => v.isLoggedInUser)?.userId ?? '' }}
        infiniteScroll={true}
        loadEarlier={true}
        onLoadEarlier={onLoadEarlier}
        renderLoadEarlier={() => null}
        alwaysShowSend={true}
        renderAvatar={renderAvatar}
        renderTime={() => null}
        renderChatEmpty={() => <ChatEmpty isAnonymous={item.isAnonymous} isLoading={!create} />}
        renderMessage={renderMessage}
        renderMessageText={renderMessageText}
        renderBubble={renderBubble}
        renderSystemMessage={renderSystemMessage}
        renderDay={renderDay}
        placeholder={'Skriv din melding…'}
        renderSend={renderSend}
        renderInputToolbar={renderInputToolbar}
        renderComposer={renderComposer}
        bottomOffset={getBottomSpace()}
        renderActions={renderActions}
        onLongPress={onLongPress}
        locale={'nb'}
        minInputToolbarHeight={channelData?.participants && channelData?.participants.length <= 1 ? 0 : 60}
        minComposerHeight={28}
        maxComposerHeight={106}
        shouldUpdateMessage={() => {
          if (shouldUpdateMessage) {
            setShouldUpdateMessage(false)
            return true
          }
          return false
        }}
        parsePatterns={(linkStyle) => [{ type: 'url', style: linkStyle, onPress: handleUrlPress }]}
        onInputTextChanged={onInputTextChanged}
        isTyping={!!isTyping}
        renderFooter={() => <ChatFooter isAnonymous={channelData?.isAnonymous} isTyping={isTyping} />}
      />
      {channelData && <ChatInputActionShareContacts ref={shareContactsRef} channel={channelData} senderId={user?.userId ?? ''} />}
    </SafeAreaView>
  )
}

export default ChatView
