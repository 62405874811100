import { SafeAreaView } from 'react-native-safe-area-context'
import TabMenus from '@/components/TabMenus'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Components
import ScreenTitle from '@/components/ScreenTitle'
import MainContents from '@/components/contents/Main'

let ContentsScreen = () => {
  const { user } = useSelector((state: RootState) => state.user)
  if (!user) return <></>
  return (
    <SafeAreaView style={{ flexDirection: 'column', flex: 1 }}>
      <ScreenTitle title="Dine tjenester" />
      <MainContents />
      <TabMenus />
    </SafeAreaView>
  )
}

export default ContentsScreen
