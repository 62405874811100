import { useState } from 'react'
import { View, StyleSheet, Pressable } from 'react-native'

// Components
import { Text, Icon } from '@rneui/themed'
import ArticleContent from '@/components/contents/Main/ArticleContent'

// Themes
import theme from '@/config/themes'

// Types
type FAQsProps = {
  data: any
}

const ParentItem = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const onPress = () => setIsExpanded(!isExpanded)
  return (
    <View>
      <Pressable style={styles.parentItemHeaderContainer} onPress={onPress}>
        <Text h4 h4Style={{ flex: 1, fontSize: 20, marginRight: 20, lineHeight: 20 }}>
          {data?.flatData?.title}
        </Text>
        <Icon type="entypo" name={isExpanded ? 'chevron-up' : 'chevron-down'} color={theme.colors.primary} />
      </Pressable>
      {isExpanded && (
        <>
          <ArticleContent content={data?.flatData?.content ?? ''} containerStyle={styles.parentItemContentContainer} />
          {data?.flatData?.subFaqs?.map((v: any, i: number) => (
            <ChildItem key={`${data.id}-${i}`} data={v} />
          ))}
        </>
      )}
    </View>
  )
}

const ChildItem = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const onPress = () => setIsExpanded(!isExpanded)
  return (
    <View>
      <Pressable style={styles.childItemHeaderContainer} onPress={onPress}>
        <Text h4 h4Style={{ flex: 1, fontSize: 16, marginRight: 20, lineHeight: 16 }}>
          {data?.flatData?.title}
        </Text>
        <Icon type="entypo" name={isExpanded ? 'chevron-up' : 'chevron-down'} color={theme.colors.primary} />
      </Pressable>
      {isExpanded && (
        <>
          <ArticleContent content={data?.flatData?.content ?? ''} containerStyle={styles.childItemContentContainer} />
        </>
      )}
    </View>
  )
}

const FAQs = ({ data }: FAQsProps) => {
  if (!data) return null
  return (
    <View style={styles.mainContainer}>
      {data.map((v: any, i: number) => (
        <ParentItem key={`${v.id}-${i}`} data={v} />
      ))}
    </View>
  )
}

export default FAQs

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexBasis: 'auto', // Web
    marginVertical: 10,
    marginHorizontal: 20,
    paddingVertical: 20,
    borderRadius: 8,
    backgroundColor: theme.colors.white,
  },

  parentItemContainer: {
    padding: 10,
  },
  parentItemHeaderContainer: {
    padding: 10,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  parentItemContentContainer: {
    paddingHorizontal: 0,
    paddingBottom: 10,
    marginHorizontal: 20,
    borderColor: '#e5e5e5',
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },

  childItemHeaderContainer: {
    padding: 10,
    paddingHorizontal: 0,
    marginHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: '#e5e5e5',
    borderBottomWidth: 1,
  },
  childItemContentContainer: {
    paddingHorizontal: 0,
    paddingBottom: 10,
    marginHorizontal: 20,
    borderColor: '#e5e5e5',
    borderBottomWidth: 1,
  },
})
