import { View } from 'react-native'

// Components
import ScreenSubpageLayout from '@/components/settings/ScreenSubpageLayout'
import Privacypolicy from '@/components/contents/Privacypolicy'

const SettingsPrivacyScreen = () => {
  return (
    <ScreenSubpageLayout title="Personvernerklæring">
      <View style={{ marginTop: 15, marginBottom: 30 }}>
        <Privacypolicy />
      </View>
    </ScreenSubpageLayout>
  )
}

export default SettingsPrivacyScreen
