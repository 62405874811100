import React, { useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { Input } from '@rneui/themed'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Theme
import theme from '@/config/themes'

// Components
import SubmitButton from '@/components/login/SubmitButton'

// Types
import type { ILoginScreenAuth } from '@/types'
import axios from 'axios'
interface CodeFormProps {
  onSuccess: (auth: ILoginScreenAuth) => void
  auth: ILoginScreenAuth
}

function CodeForm({ onSuccess, auth }: CodeFormProps) {
  const [confirmCode, setConfirmCode] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Validation
  const confirmCodeRegex = new RegExp('^[0-9]{6}$')
  const onChangeText = (code: string): void => {
    setConfirmCode(code)
    setIsValid(!!code && confirmCodeRegex.test(code))
  }

  // Submit
  const onSubmit = async (): Promise<void> => {
    toast.hideAll()
    if (!isValid) {
      toast.show('Feltet er obligatorisk.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      return
    }

    try {
      setIsLoading(true)
      if (!auth) {
        setIsLoading(false)
        toast.show('Beklager, noe gikk galt.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
        return
      }

      const res = await axios.post('api/activation/verifyotp', { requestId: auth.requestId, otp: confirmCode })
      if (!res.data) {
        setIsLoading(false)
        toast.show('Feil kode, prøv igjen.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
        return
      }
      setIsLoading(false)
      auth.verifyCode = confirmCode
      onSuccess(auth)
    } catch (error: any) {
      setIsLoading(false)
      toast.show(error.message, { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      xConsole().error(error, 'CodeForm')
    }
  }
  return (
    <View>
      <Input
        placeholder="Fyll inn koden"
        keyboardType="number-pad"
        containerStyle={styles.inputContainer}
        inputStyle={[styles.input, { textAlign: 'center' }]}
        inputContainerStyle={{ borderBottomWidth: 0 }}
        placeholderTextColor={theme.colors.black}
        onChangeText={onChangeText}
        onSubmitEditing={onSubmit}
        disabledInputStyle={{ opacity: 1 }}
        disabled={isLoading}
        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
        autoComplete="sms-otp"
        textContentType="oneTimeCode"
        testID="CodeFormInput"
      />
      <SubmitButton title="BEKREFT KODEN" onPress={onSubmit} loading={isLoading} valid={isValid} />
    </View>
  )
}

export default CodeForm

const styles = StyleSheet.create({
  inputContainer: {
    height: 55,
    paddingHorizontal: 0,
  },
  input: {
    height: 55,
    fontSize: 18,
    textAlign: 'center',
    borderWidth: 1.5,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
})
