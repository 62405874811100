import { useEffect, useState, useCallback } from 'react'
import { StyleSheet, StyleProp, ViewStyle } from 'react-native'

import { SectionGrid } from 'react-native-super-grid'
import { useFocusEffect } from '@react-navigation/native'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// APIs
import { contentApi } from '@/slices/contentsSlice'

// Components
import ItemContent from '@/components/contents/Main/ItemContent'

// Helpers
import getTargetGroupsByUser from '@/plugins/helpers/getTargetGroupsByUser'

// Types
type QueryContentsProps = {
  categories: Array<string>
  pushFirstItemAsFeatured?: boolean
  containerStyle?: StyleProp<ViewStyle>
  renderHeader?: JSX.Element | null | undefined
  renderFooter?: JSX.Element | null | undefined
  renderWidgets?: JSX.Element | null | undefined
}

const QueryContents = ({ categories, pushFirstItemAsFeatured, containerStyle, renderHeader, renderFooter, renderWidgets }: QueryContentsProps) => {
  const { user } = useSelector((state: RootState) => state.user)
  const [sections, setSections] = useState([])

  const { data: pagesData } = contentApi.endpoints.getPages.useQuery(
    {
      targetGroups: user ? getTargetGroupsByUser(user) : [],
      categories,
    },
    {
      skip: false,
      refetchOnMountOrArgChange: true,
    }
  )

  useFocusEffect(
    useCallback(() => {
      getContents()
    }, [pagesData])
  )

  const getContents = useCallback(async () => {
    try {
      const _sections: any = []
      const _contents: any = []

      // Header
      _sections.push({
        title: 'header',
      })

      if (pagesData) {
        pagesData.forEach((v: any, i: number) => {
          if (i === 0) {
            if (pushFirstItemAsFeatured) {
              _sections.push({
                title: 'contents',
                data: [{ id: v.id, data: v.flatData }],
              })
              if (renderWidgets) {
                _sections.push({
                  title: 'widgets',
                  data: [{ widgets: renderWidgets }],
                })
              }
            } else {
              if (renderWidgets) {
                _sections.push({
                  title: 'widgets',
                  data: [{ widgets: renderWidgets }],
                })
              }
              _sections.push({
                title: 'contents',
                data: [{ id: v.id, data: v.flatData }],
              })
            }
          } else {
            _contents.push({ id: v.id, data: v.flatData })
          }
        })

        // Pre rest contents
        _sections.push({
          title: 'contents',
          data: [],
        })

        _contents.forEach((v: any, i: number) => {
          const lastSection = _sections[_sections.length - 1]
          const beforeLastSection = _sections[_sections.length - 2]

          if (v.data?.fullWidth) {
            // Full: Push to single section
            if (lastSection.data.length === 0) {
              lastSection.data.push({ id: v.id, data: v.data })
            } else {
              _sections.push({
                title: 'contents',
                data: [{ id: v.id, data: v.data }],
              })
            }
          } else {
            const shouldFill =
              beforeLastSection.title === 'contents' &&
              (beforeLastSection.data.length === 0 ||
                (!(beforeLastSection.data.length % 2 === 0) && beforeLastSection.data.some((v: any) => !v.data?.fullWidth)))

            if (shouldFill) {
              beforeLastSection.data.push({ id: v.id, data: v.data })
            } else if (lastSection.data.some((v: any) => v.data?.fullWidth)) {
              _sections.push({
                title: 'contents',
                data: [{ id: v.id, data: v.data }],
              })
            } else {
              lastSection.data.push({ id: v.id, data: v.data })
            }
          }
        })
      } else {
        // Should alway shows widgets
        if (renderWidgets) {
          _sections.push({
            title: 'widgets',
            data: [{ widgets: renderWidgets }],
          })
        }
      }
      // Footer
      _sections.push({
        title: 'footer',
      })

      // Save data
      setSections(_sections)
    } catch (error) {}
  }, [pagesData])

  useEffect(() => {
    try {
      if (sections.length && renderWidgets) {
        const _sections = [...sections] as any
        const index = _sections.findIndex((v: any) => v.title === 'widgets')
        if (index !== -1) {
          _sections[index].data = [{ widgets: renderWidgets }]
          setSections(_sections)
        }
      }
    } catch (error) {}
  }, [renderWidgets])

  const renderItem = useCallback(({ item, section }) => {
    if (section.title === 'contents') {
      return <ItemContent data={item} />
    }
    if (section.title === 'widgets') {
      return item.widgets
    }
    return <></>
  }, [])

  const renderSectionHeader = ({ section }) => {
    return section.title === 'header' && renderHeader ? renderHeader : null
  }

  const renderSectionFooter = ({ section }) => {
    return section.title === 'footer' && renderFooter ? renderFooter : null
  }

  return (
    <SectionGrid
      style={[styles.mainContainer, containerStyle]}
      spacing={20}
      maxItemsPerRow={2}
      additionalRowStyle={styles.additionalRow}
      sections={sections}
      renderItem={renderItem}
      stickySectionHeadersEnabled={false}
      renderSectionHeader={renderSectionHeader}
      renderSectionFooter={renderSectionFooter}
    />
  )
}

export default QueryContents

const styles = StyleSheet.create({
  mainContainer: {
    flex: 2,
    flexFlow: 'column wrap',
  },
  additionalRow: {
    marginTop: 0,
  },
})
