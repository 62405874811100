import { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, Icon } from '@rneui/themed'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { fetchUser } from '@/slices/userSlice'

// APIs
import setAvailability from '@/api/auth/setAvailability'

// Themes
import theme from '@/config/themes'

// Types
import type { IAvailabilityName } from '@/types'

const SettingsStatus = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.user)

  if (!user) {
    return null
  }

  useEffect(() => {
    dispatch(fetchUser())
  }, [])

  const doSetAvailability = async (availabilityName: IAvailabilityName) => {
    await setAvailability(availabilityName)
    toast.hideAll()
    toast.show(`Du har byttet tilgjengelighet.`, { type: 'main', data: { title: 'Byttet!', icon: 'success' } })
    dispatch(fetchUser())
  }

  return (
    <View>
      <Button
        title="Tilgjengelig"
        type="clear"
        containerStyle={styles.buttonContainer}
        buttonStyle={[styles.button, { borderColor: user.availability === 'online' ? theme.colors.primary : 'transparent' }]}
        titleStyle={[styles.buttonTitle, { fontFamily: user.availability === 'online' ? 'FSJoeyMedium' : 'FSJoey' }]}
        onPress={() => {
          doSetAvailability('online')
        }}
      >
        Tilgjengelig
        <Icon name="circle" color="#38A751" />
      </Button>

      <Button
        title="Tilgjengelig"
        type="clear"
        containerStyle={styles.buttonContainer}
        buttonStyle={[styles.button, { borderColor: user.availability === 'busy' ? theme.colors.primary : 'transparent' }]}
        titleStyle={[styles.buttonTitle, { fontFamily: user.availability === 'busy' ? 'FSJoeyMedium' : 'FSJoey' }]}
        onPress={() => {
          doSetAvailability('busy')
        }}
      >
        Opptatt
        <Icon name="circle" color="#e74137" />
      </Button>

      <Button
        title="Tilgjengelig"
        type="clear"
        containerStyle={styles.buttonContainer}
        buttonStyle={[styles.button, { borderColor: user.availability === 'invisible' ? theme.colors.primary : 'transparent' }]}
        titleStyle={[styles.buttonTitle, { fontFamily: user.availability === 'invisible' ? 'FSJoeyMedium' : 'FSJoey' }]}
        onPress={() => {
          doSetAvailability('invisible')
        }}
      >
        Ikke synlig
        <Icon name="circle" color="#adadad" />
      </Button>
    </View>
  )
}

export default SettingsStatus

const styles = StyleSheet.create({
  buttonContainer: {
    marginBottom: 15,
  },
  button: {
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: theme.colors.white,
    borderWidth: 2,
    borderColor: 'transparent',
    borderRadius: 10,
    justifyContent: 'space-between',
  },
  buttonTitle: {
    fontFamily: 'FSJoey',
    color: theme.colors.black,
    fontSize: 18,
  },
})
