import { createContext, useContext, useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { ApplicationInsights, IApplicationInsights } from '@microsoft/applicationinsights-web'
import { debounce } from 'lodash'
import * as Device from 'expo-device'

const ReactNativeManualDevicePlugin = Platform.select({
  default: () => require('@microsoft/applicationinsights-react-native').ReactNativeManualDevicePlugin,
  web: () => null,
})()
const RNMPlugin = Platform.select({
  default: () => new ReactNativeManualDevicePlugin(),
  web: () => null,
})()

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Application Insights
if (Platform.OS !== 'web') {
  const deviceInfoModule = {
    getModel: () => Device.modelName,
    getDeviceType: () => (Platform.OS !== 'web' ? 'Phone' : 'Browser'),
    getUniqueId: () => '',
  }
  RNMPlugin.setDeviceInfoModule(deviceInfoModule)
}

const _appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '7820a48f-a08d-466e-9331-a741995e9b60',
    autoTrackPageVisitTime: true,
    extensions: RNMPlugin ? [RNMPlugin] : [],
    // enableDebug: true,
  },
})

_appInsights.loadAppInsights()

// Types
import type { NavigationContainerRef } from '@react-navigation/native'
import type { RootNavigatorParamList } from '@/types'
type AppInsightsContextData = {
  appInsights: IApplicationInsights
}
type AppInsightsProps = {
  children: React.ReactNode
  navigation: NavigationContainerRef<RootNavigatorParamList>
}
const AppInsightsContext = createContext<AppInsightsContextData>({} as AppInsightsContextData)

const AppInsightsProvider: React.FC<AppInsightsProps> = ({ children, navigation }) => {
  const [appInsights, setAppInsights] = useState<AppInsightsContextData['appInsights']>(_appInsights)
  const { user } = useSelector((state: RootState) => state.user)

  // User
  useEffect(() => {
    const userId = user && user.userId ? user.userId : ''
    _appInsights.context.user.id = userId
    _appInsights.context.user.authenticatedId = userId
  }, [user])

  // On Route changes
  const onNavigationStateChanged = debounce(async () => {
    const currentRoute = navigation.getCurrentRoute()
    if (currentRoute) {
      appInsights.trackPageView({ name: currentRoute.name })
    }
  }, 500)
  useEffect(() => {
    const unsubscribe = navigation.addListener('state', onNavigationStateChanged)
    return unsubscribe
  }, [])

  return <AppInsightsContext.Provider value={{ appInsights }}>{children}</AppInsightsContext.Provider>
}

function useAppInsights(): AppInsightsContextData {
  const context = useContext(AppInsightsContext)
  if (!context) {
    throw new Error('useAppInsights must be used within an AppInsightsProvider')
  }
  return context
}

export { AppInsightsContext, AppInsightsProvider, useAppInsights }
