import { Platform } from 'react-native'
import { configureStore, Action } from '@reduxjs/toolkit'
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import { ThunkAction } from 'redux-thunk'
import rootReducer, { RootState } from './rootReducer'

// Redux Query
import { chatApi } from '@/api/chatApi'
import { contentApi } from '@/slices/contentsSlice'

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

// Select storage type platform
const storage = Platform.select({
  default: () => require('@react-native-async-storage/async-storage').default,
  web: () => require('redux-persist/lib/storage').default,
})()

const persistConfig = {
  key: 'root',
  storage: storage,
}

const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer) // @TODO: Find types for <any,any>

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    }).concat(contentApi.middleware, chatApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)
export default store
