import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { Icon, Button } from '@rneui/themed'
import * as ImagePicker from 'expo-image-picker'
import { useActionSheet } from '@expo/react-native-action-sheet'
import axios from 'axios'
import FormData from 'form-data'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { fetchUser } from '@/slices/userSlice'

// Components
import Avatar from '@/components/Avatar'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Themes
import theme from '@/config/themes'

// Types
type SettingsAvatarUploadProps = {
  onPress?: () => void
  newDefaultAvatarId?: string
  isOnPressShouldDoUpload?: boolean
}

const SettingsAvatarUpload = ({ onPress, newDefaultAvatarId, isOnPressShouldDoUpload = false }: SettingsAvatarUploadProps) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.user)
  if (!user) {
    return null
  }
  const { showActionSheetWithOptions } = useActionSheet()

  const doOnPress = () => {
    if (onPress && !isOnPressShouldDoUpload) {
      onPress()
    } else if (isOnPressShouldDoUpload) {
      showActionSheetWithOptions(
        {
          options: ['Velg bilde', 'Ta bilde', 'Avbryt'],
          cancelButtonIndex: 2,
        },
        (buttonIndex) => {
          if (buttonIndex === 0 || buttonIndex === 1) {
            selectImage({ useCamera: buttonIndex === 1 })
          }
        }
      )
    }
  }

  const selectImage = async ({ useCamera = false }) => {
    try {
      let result: any
      const ImagePickerOptions: ImagePicker.ImagePickerOptions = {
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 4],
        quality: 1,
      }
      if (!useCamera) {
        result = await ImagePicker.launchImageLibraryAsync(ImagePickerOptions)
      } else {
        const permissionResult = await ImagePicker.requestCameraPermissionsAsync()
        if (permissionResult.granted === false) {
          alert('You have to allow accessing camera to take a picture')
          return
        }
        result = await ImagePicker.launchCameraAsync(ImagePickerOptions)
      }

      if (!result.cancelled) {
        const avatarId = await uploadImageAsync(result.assets[0].uri)
        if (avatarId) {
          dispatch(fetchUser())
        }
      }
    } catch (error: any) {
      xConsole().error(error, 'AvatarUpload')
    }
  }

  const uploadImageAsync = async (uri: string) => {
    try {
      const formData = new FormData()
      const uriParts = uri.split('.')
      const fileType = uriParts[uriParts.length - 1]
      formData.append('file', {
        uri: uri,
        name: `photo.${fileType}`,
        type: `image/${fileType}`,
      })
      const res = await axios.post('/api/avatar', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      return res.data
    } catch (error: any) {
      xConsole().error(error, 'AvatarUpload')
      return null
    }
  }

  return (
    <View style={styles.mainContainer}>
      <Avatar
        placeholderTitle={user.displayName?.charAt(0).toUpperCase()}
        avatarId={newDefaultAvatarId || user.avatarId}
        size={'large'}
        props={{ Component: TouchableOpacity, onPress: doOnPress }}
      >
        <Icon name="camera" type="feather" size={12} color={theme.colors.white} containerStyle={styles.uploadIconContainer} />
      </Avatar>
      <Button
        containerStyle={{
          width: 200,
        }}
        title="ENDRE PROFILBILDE"
        type="clear"
        titleStyle={styles.buttonTitle}
        onPress={doOnPress}
      />
    </View>
  )
}

export default SettingsAvatarUpload

const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadIconContainer: {
    position: 'absolute',
    bottom: -1,
    right: -1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    borderRadius: 100,
    backgroundColor: '#c6c6c6',
  },
  buttonTitle: {
    fontFamily: 'FSJoeyMedium',
    color: theme.colors.primary,
  },
})
