/*
  Check if user's role is helper
  Return: boolean
*/

const isHelper = (roles: Array<string>) => {
  return ['superadmin', 'helper', 'orgadmin'].some((v) => roles?.includes(v))
}

export default isHelper
