import { useState } from 'react'

/**
 * useExtendedState (useState)
 *
 * @param  {any} initialState (any)
 * @returns {any} current initialState
 * @example
 * import useExtendedState from '@/plugins/helpers/useExtendedState'
 * const [isLoading, setIsLoading, getIsLoading] = useExtendedState(false)
 * // Then use inside a function
 * const isLoading = await getIsLoading()
 */

function useExtendedState<T>(initialState: T) {
  const [state, setState] = useState<T>(initialState)
  const getLatestState = () => {
    return new Promise<T>((resolve, reject) => {
      setState((s) => {
        resolve(s)
        return s
      })
    })
  }

  return [state, setState, getLatestState] as const
}

export default useExtendedState
