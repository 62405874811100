import axios, { AxiosResponse } from 'axios'
import queryString from 'query-string'

// Configs
import { koplingConfig, getStore } from '@/config'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IGetTokenReturn = Promise<AxiosResponse>

/*
  Get token exchange from the API, using axios with x-www-form-urlencoded and queryString
  Example usage: Short life token for external API
*/
async function getTokenExchange(): IGetTokenReturn {
  try {
    const { token } = getStore().getState().user
    const res = await axios({
      method: 'POST',
      url: '/connect/token',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: queryString.stringify({
        grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
        scope: 'external',
        client_id: koplingConfig.clientId,
        subject_token: token.access_token,
      }),
    })
    return res
  } catch (error: any) {
    xConsole().error(error, 'getTokenExchange')
    return error.response as AxiosResponse
  }
}

export default getTokenExchange
