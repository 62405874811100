import { StyleSheet } from 'react-native'
import { Text } from '@rneui/themed'
import theme from '@/config/themes'

function ChatFooter(props: any) {
  const { isTyping, isAnonymous } = props
  if (!isTyping) return null
  return <Text style={[styles.text, { color: isAnonymous ? theme.colors.white : theme.colors.black }]}>{isTyping} skriver…</Text>
}

export default ChatFooter
const styles = StyleSheet.create({
  text: {
    fontSize: 10,
    marginLeft: 20,
  },
})
