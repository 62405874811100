import { StyleSheet, View } from 'react-native'
import { ListItem, Text, Icon } from '@rneui/themed'

// Components
import Avatar from '@/components/Avatar'
// Theme
import theme from '@/config/themes'

//Types
import type { IInstitutionListItem } from '@/types'
type ListContactItemProps = {
  item: IInstitutionListItem
  onPress: any
  headerBgColor?: string
  placeholderColor?: string
  itemListContainerBgColor?: string
}

const ContactItem = ({ item, onPress, headerBgColor = '', placeholderColor = '#ffffff', itemListContainerBgColor = '' }: ListContactItemProps) => {
  if (item.type === 'item') {
    return (
      <ListItem
        key={item.props.userId}
        onPress={onPress}
        bottomDivider
        containerStyle={[styles.itemListContainer, { backgroundColor: itemListContainerBgColor || theme.colors.white }]}
      >
        <Avatar
          avatarId={item.props.avatarId}
          placeholderTitle={item.props.displayName || ''}
          availability={item.props.availability}
          props={{
            placeholderStyle: { backgroundColor: placeholderColor },
          }}
        />
        <ListItem.Content>
          <ListItem.Title numberOfLines={1}>{item.props.displayName}</ListItem.Title>
          <ListItem.Subtitle numberOfLines={1} style={{ color: theme?.colors?.grey2 }}>
            {item.props.title}
          </ListItem.Subtitle>
        </ListItem.Content>
        <Icon type="entypo" name="chevron-right" color={theme.colors.primary} />
      </ListItem>
    )
  } else {
    return (
      <View key={item.props.id} style={[styles.header, { backgroundColor: headerBgColor || theme.colors.bodybg }]}>
        <Text key={item.props.id} style={styles.headerText}>
          {item.props.title}
        </Text>
      </View>
    )
  }
}

export default ContactItem

const styles = StyleSheet.create({
  header: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: 20,
    backgroundColor: theme.colors.bodybg,
    height: 40,
  },
  headerText: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 20,
  },
  itemListContainer: {
    height: 73,
    paddingHorizontal: 20,
    marginHorizontal: 20,
    marginBottom: 10,
    borderRadius: 8,
  },
})
