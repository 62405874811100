import { useEffect, useState, useRef } from 'react'
import { View, StyleSheet } from 'react-native'
import axios from 'axios'

// Components
import { Text } from '@rneui/themed'
import ContactProfileOverlay from '@/components/contacts/institutions/ProfileOverlay'
import ContactItem from '@/components/contacts/institutions/Item'

// Themes
import theme from '@/config/themes'

// Types
import type { IUserOther, IContactOverlayRef } from '@/types'

type UsersProps = {
  data: any
}

const Item = ({ data, onPress }) => {
  const [user, setUser] = useState<IUserOther>()

  const getUser = async () => {
    try {
      const res = await axios.get(`/api/user/${data}`)
      setUser({
        userId: res.data.userId,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        displayName: res.data.displayName,
        avatarId: res.data.avatarId,
        description: res.data.description,
        availability: res.data.availability,
        title: res.data.title,
      })
    } catch (error) {}
  }

  useEffect(() => {
    getUser()
  }, [data])

  if (!user) return null
  return (
    <ContactItem
      key={user.userId}
      item={{ type: 'item', props: user }}
      onPress={() => {
        onPress(user)
      }}
      headerBgColor={theme.colors.white}
      placeholderColor={'#ffffff'}
      itemListContainerBgColor={'#f7f7f7'}
    />
  )
}

const Users = ({ data }: UsersProps) => {
  const ContactOverlayRef = useRef<IContactOverlayRef>(null)

  const openContact = (data: IUserOther) => {
    ContactOverlayRef.current?.setIsActive(true, data)
  }

  if (!data) return null
  return (
    <View style={styles.mainContainer}>
      {data.map((v: any, i: number) => (
        <View key={i}>
          <Text style={{ fontFamily: 'FSJoeyMedium', fontSize: 18, marginHorizontal: 20, marginBottom: 10 }}>{v.title}</Text>
          {v.items.map((v: any, i: number) => (
            <Item key={i} data={v} onPress={openContact} />
          ))}
        </View>
      ))}
      <ContactProfileOverlay ref={ContactOverlayRef} />
    </View>
  )
}

export default Users

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexBasis: 'auto', // Web
    marginVertical: 10,
  },
})
