import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { Text, Icon } from '@rneui/themed'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StatusBar } from 'expo-status-bar'

// Navigation
import { useNavigation } from '@react-navigation/native'

// Components
import Avatar from '../Avatar'

// Themes
import theme from '@/config/themes'

// Types
import { Routes } from '@/config/routes'
type ChatHeaderProps = {
  id: string | null
  topic: string
  topicDescription: string
  topicAvatarId: string
  availability: string
  isAnonymous: boolean
}
function ChatHeader({ id, topic, topicDescription, topicAvatarId, availability, isAnonymous }: ChatHeaderProps) {
  const navigation = useNavigation()
  const insets = useSafeAreaInsets()

  return (
    <View
      style={[
        styles.mainContainer,
        {
          paddingTop: 10 + insets.top,
          backgroundColor: !isAnonymous ? '#f2efea' : '#1d1d1d',
        },
      ]}
    >
      <TouchableOpacity
        onPress={() => {
          if (navigation.canGoBack()) {
            navigation.goBack()
          } else {
            navigation.replace('Root', { screen: Routes.Channels })
          }
        }}
        style={styles.backButton}
      >
        <Icon type="entypo" name="chevron-left" color={!isAnonymous ? theme.colors.black : theme.colors.white} size={38} />
      </TouchableOpacity>

      <View style={styles.profileContainer}>
        <Avatar
          avatarId={topicAvatarId}
          placeholderTitle={topic}
          isAnonymous={isAnonymous}
          props={{
            containerStyle: [styles.avatar],
          }}
          availability={availability}
        />
        <View>
          <Text h4 h4Style={{ fontSize: 18, lineHeight: 20, color: !isAnonymous ? '#2b2c2b' : '#ffffff' }}>
            {topic}
          </Text>
          {!!topicDescription && <Text style={{ color: !isAnonymous ? '#666666' : '#cecece' }}>{topicDescription}</Text>}
        </View>
      </View>
      <StatusBar style={!isAnonymous ? 'dark' : 'light'} />
    </View>
  )
}

export default ChatHeader

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 10,
    marginBottom: 1,
  },
  backButton: {
    padding: 10,
    marginLeft: -20,
    marginTop: -15,
    marginBottom: -15,
  },
  profileContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    marginRight: 20,
  },
  status: { borderRadius: 20, marginRight: -2, marginBottom: -2 },
})
