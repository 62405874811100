import { StyleSheet, StyleProp, ImageStyle } from 'react-native'
import { Avatar as RNEUIAvatar, AvatarProps as RNEUIAvatarProps } from '@rneui/themed'
import { koplingConfig } from '@/config'

// Helpers
import getHexColorFromAvailabilityName from '@/plugins/helpers/getHexColorFromAvailabilityName'

// Types
import type { IAvailabilityName } from '@/types'
type AvatarProps = {
  avatarId?: any
  size?: ('small' | 'medium' | 'large' | 'xlarge') | number
  placeholderTitle?: string | null
  availability?: IAvailabilityName | string
  availabilitySize?: number
  availabilityStyle?: StyleProp<ImageStyle>
  isAnonymous?: boolean
  props?: RNEUIAvatarProps
  children?: React.ReactElement
}
const Avatar = ({
  avatarId = '',
  availability,
  availabilitySize = 10,
  availabilityStyle,
  size = 'medium',
  placeholderTitle = '',
  isAnonymous = false,
  props,
  children,
}: AvatarProps) => {
  return (
    <RNEUIAvatar
      title={placeholderTitle?.charAt(0).toUpperCase()}
      source={{ uri: `${koplingConfig.apiUrl}/api/avatar/${avatarId}` }}
      size={size}
      rounded
      containerStyle={{ backgroundColor: !isAnonymous ? '#f8f8f8' : '#2b2c2b' }}
      titleStyle={{ color: !isAnonymous ? '#2b2c2b' : '#ffffff', position: 'absolute', width: '100%' }}
      imageProps={{ placeholderStyle: { opacity: 0, position: 'absolute', width: '100%' }, containerStyle: { zIndex: 1 } }}
      {...props}
    >
      <>
        {!!availability && (
          <RNEUIAvatar.Accessory
            size={availabilitySize}
            name=""
            style={StyleSheet.flatten([
              {
                borderRadius: 20,
                backgroundColor: getHexColorFromAvailabilityName(availability),
                marginRight: 1,
                marginBottom: 0,
                shadowOpacity: 0,
                shadowColor: 'transparent',
                zIndex: 1,
              },
              availabilityStyle,
            ])}
          />
        )}
        {children}
      </>
    </RNEUIAvatar>
  )
}

export default Avatar
