import axios, { AxiosResponse } from 'axios'
import { xConsole } from '@/plugins/helpers/xConsole'

/*
  Send data to Slack channel for debugging
  Params: ISendDataToSlackArgs
  Return: ISendDataToSlackReturn
*/

// Types
export type ISendDataToSlackReturn = Promise<AxiosResponse>
export type ISendDataToSlackArgs = {
  title?: string
  data?: any
}

async function sendDataToSlack(args: ISendDataToSlackArgs): ISendDataToSlackReturn {
  try {
    let text = ''
    if (args.title) {
      text += args.title
    }
    if (args.data) {
      text += '\n'
      text += '```'
      text += typeof args.data === 'object' ? JSON.stringify(args.data, null, 2) : args.data
      text += '```'
    }
    const res = await axios.post('https://hooks.slack.com/services/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', {
      text: text,
    })
    return res
  } catch (error: any) {
    xConsole().error(error, 'sendDataToSlack')
    return error.response
  }
}

export default sendDataToSlack
