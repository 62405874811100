import { useState, useRef, useEffect, useCallback } from 'react'
import { Dimensions, View } from 'react-native'
import axios from 'axios'
import theme from '@/config/themes'
import { uniq } from 'lodash'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { fetchInstitutions, fetchCounties } from '@/slices/institutionsSlice'

// APIs
import acceptTermsConditions from '@/api/auth/acceptTermsConditions'

// Components
import SubmitButton from './SubmitButton'
import InstitutionSelector from '@/components/institutions/selector'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import type { ILoginScreenAuth, IInstitutionSelectorRef } from '@/types'
type InstitutionsFormProps = {
  onSuccess: (auth: ILoginScreenAuth) => void
  auth: ILoginScreenAuth
  setSubtitle: (v: string) => void
  resetSubtitle: () => void
}

// Variables
const { height } = Dimensions.get('window')

function InstitutionsForm({ onSuccess, auth, setSubtitle, resetSubtitle }: InstitutionsFormProps) {
  const dispatch = useDispatch()
  const [selectedInstitution, setSelectedInstitution] = useState(auth?.institution || null)
  const { institutions } = useSelector((state: RootState) => state.institutions)

  const institutionSelectorRef = useRef<IInstitutionSelectorRef>(null)

  useEffect(() => {
    dispatch(fetchInstitutions())
  }, [])

  useEffect(() => {
    if (auth?.reference === 'click-arrow') {
      openSelector()
    }
  }, [auth])

  useEffect(() => {
    const institutionName = findInstitutionName(selectedInstitution)
    if (institutionName) {
      setSubtitle(`Du har valgt ${institutionName}.`)
    } else {
      resetSubtitle()
    }
  }, [selectedInstitution, institutions])

  const findInstitutionName = useCallback(
    (id: string | null) => {
      if (!institutions) return ''
      return institutions.find((i) => i.institutionId === id)?.institutionName || ''
    },
    [institutions]
  )

  const openSelector = () => {
    axios.defaults.headers.common['Authorization'] = auth?.token?.access_token ? `Bearer ${auth.token.access_token}` : ''
    if (!institutionSelectorRef.current) {
      return
    }
    institutionSelectorRef.current.setIsActive(true)
  }

  const onSelect = (active: boolean, id: string) => {
    setSelectedInstitution(id)
  }

  const login = async () => {
    try {
      if (!auth || !auth.token || !auth.firstName || !auth.lastName || !auth.countyId || selectedInstitution === null) {
        return
      }
      auth.institution = selectedInstitution || ''

      axios.defaults.headers.common['Authorization'] = auth.token.access_token ? `Bearer ${auth.token.access_token}` : ''
      await axios.put('/api/user/assigninstitutions', selectedInstitution ? [selectedInstitution] : ['none-selected'])
      onSuccess(auth)
      acceptTermsConditions({ accept: true })
    } catch (error: any) {
      xConsole().error(error, 'InstitutionsForm.tsx (login)')
    }
  }

  return (
    <>
      <View>
        <SubmitButton
          title="VELG SKOLE"
          onPress={openSelector}
          loading={false}
          valid={true}
          buttonStyle={{ backgroundColor: theme.colors.teal }}
          titleStyle={{ color: '#fff' }}
          icon={{
            name: 'notebook-multiple',
            type: 'material-community',
            size: 18,
            color: '#fff',
          }}
        />
      </View>
      <View>
        <SubmitButton
          title="GÅ VIDERE"
          onPress={login}
          loading={false}
          valid={selectedInstitution !== null}
          disabled={selectedInstitution === null}
        />
      </View>
      <InstitutionSelector
        ref={institutionSelectorRef}
        bottomSheetTopInset={-(height * 0.5)}
        onSelect={onSelect}
        onSubmit={login}
        defaultSelected={selectedInstitution !== null ? [selectedInstitution] : []}
        filterCounty={auth?.countyId}
        multiple={false}
      />
    </>
  )
}

export default InstitutionsForm
