import { SafeAreaView } from 'react-native-safe-area-context'

// Navigation
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

// Components
import TabMenus from '@/components/TabMenus'

// Screens
import InstitutionContacts from './InstitutionContacts'
import OrganizationContacts from './OrganizationContacts'
import EmergencyContacts from './EmergencyContacts'

// Theme
import theme from '@/config/themes'

// Types
import { Routes } from '@/config/routes'

// Variables
const Tab = createBottomTabNavigator()

const ContactsScreen = () => {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Tab.Navigator
        screenOptions={{
          // https://reactnavigation.org/docs/bottom-tab-navigator
          tabBarStyle: {
            position: 'absolute',
            paddingVertical: 5,
            paddingHorizontal: 5,
            marginVertical: 20,
            marginHorizontal: 20,
            height: 40,
            alignItems: 'center',
            borderTopWidth: 0,
            backgroundColor: '#e8e5df',
            borderRadius: 100,
            shadowColor: theme.colors.black,
            shadowOffset: { width: 0, height: 0 },
            shadowOpacity: 0.25,
            shadowRadius: 5,
            zIndex: 0,
            elevation: 0,
          },
          tabBarLabelStyle: {
            fontFamily: 'FSJoeyMedium',
            fontSize: 18,
            marginBottom: 5,
            width: '100%',
            marginLeft: 0,
          },
          tabBarItemStyle: {
            flex: 1,
            height: 30,
            borderRadius: 100,
          },
          tabBarIcon: () => null,
          tabBarInactiveBackgroundColor: '#e8e5df',
          tabBarActiveBackgroundColor: theme.colors.primary,
          tabBarActiveTintColor: theme.colors.white,
          tabBarInactiveTintColor: theme.colors.black,
        }}
      >
        <Tab.Screen name={Routes.InstitutionContacts} component={InstitutionContacts} options={{ headerShown: false, title: 'DINE' }} />
        <Tab.Screen name={Routes.OrganizationContacts} component={OrganizationContacts} options={{ headerShown: false, title: 'ALLE' }} />
        <Tab.Screen name={Routes.EmergencyContacts} component={EmergencyContacts} options={{ headerShown: false, title: '24/7' }} />
      </Tab.Navigator>
      <TabMenus />
    </SafeAreaView>
  )
}

export default ContactsScreen
