import { useEffect, useMemo } from 'react'
import { View } from 'react-native'
import { debounce } from 'lodash'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Slices
import { fetchFAQ } from '@/slices/faqsSlice'

// Components
import FAQListItem from '@/components/faq/ListItem'
import Loading from '@/components/Loading'

// Types
import type { IFAQ } from '@/types'

function FAQList() {
  const dispatch = useDispatch()

  // State
  const { faqs, isLoading: isApiFAQLoading } = useSelector((state: RootState) => state.faqs)
  const { user } = useSelector((state: RootState) => state.user)

  const doFetchFAQs = () => {
    dispatch(fetchFAQ())
  }

  const functionHandler = useMemo(() => debounce(doFetchFAQs, 3000), [])

  useEffect(() => {
    functionHandler()
  }, [user?.institutions])

  return (
    <View style={{ padding: 20, paddingBottom: 0 }}>
      {faqs && faqs.length && typeof faqs === 'object' ? (
        faqs.map((item: IFAQ) => <FAQListItem key={item.assignedId} item={item} />)
      ) : (
        <>
          <Loading text={`Laster inn ofte stilte spørsmål…`} containerStyle={{ marginTop: 60, marginBottom: 100 }} />
        </>
      )}
    </View>
  )
}

export default FAQList
