import { useEffect, useRef, useMemo, useCallback } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { Text, Button, Image } from '@rneui/themed'
import { BottomSheetModal, BottomSheetBackdrop } from '@gorhom/bottom-sheet'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Components
import ListContacts from './List'

// Theme
import theme from '@/config/themes'

// Types
import type { RootNavigatorParamList } from '@/types'
import { Routes } from '@/config/routes'

const ContactsOverlay = () => {
  const insets = useSafeAreaInsets()
  const bottomSheetModalRef = useRef<BottomSheetModal>(null)
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()

  const snapPoints = useMemo(() => ['80%'], [])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      handleDismissModalPress()
    })
    return unsubscribe
  }, [navigation])

  const handlePresentModalPress = useCallback(() => {
    if (Platform.OS !== 'web') {
      bottomSheetModalRef.current?.present()
    } else {
      navigation.navigate(Routes.Contacts)
    }
  }, [])

  const handleDismissModalPress = useCallback(() => {
    bottomSheetModalRef.current?.dismiss()
  }, [])

  const renderBackdrop = useCallback((props) => <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} />, [])

  return (
    <>
      <Image
        source={require('@/assets/images/icons/pencil-box-white.png')}
        containerStyle={[styles.fabContainer, { bottom: 80 + insets.bottom }]}
        style={styles.fabImage}
        placeholderStyle={{ backgroundColor: 'transparent' }}
        onPress={handlePresentModalPress}
      />

      <BottomSheetModal ref={bottomSheetModalRef} index={0} snapPoints={snapPoints} backdropComponent={renderBackdrop}>
        <View style={styles.bottomSheetHeader}>
          <Button
            title="LUKK"
            onPress={handleDismissModalPress}
            type="clear"
            buttonStyle={[theme._.ButtonStyle.Clear]}
            titleStyle={{ color: theme.colors.primary }}
          />
        </View>
        <Text h4 style={styles.title}>
          Velg hvem du vil snakke med
        </Text>
        <View style={{ flex: 1 }}>
          <ListContacts isWhiteBg />
        </View>
      </BottomSheetModal>
    </>
  )
}

export default ContactsOverlay

const styles = StyleSheet.create({
  bottomSheetHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  title: {
    paddingHorizontal: 20,
  },
  fabContainer: {
    position: 'absolute',
    right: 20,
    width: 66,
    height: 66,
    backgroundColor: theme.colors.primary,
    borderRadius: 100,
  },
  fabImage: {
    marginTop: 18,
    marginLeft: 18,
    width: 28,
    height: 30,
  },
})
