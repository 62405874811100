import { useState, useRef } from 'react'
import { StyleSheet, Platform, View } from 'react-native'
import { Text, Input, CheckBox } from '@rneui/themed'
import axios from 'axios'

// Helpers
import formatPhoneNumber, { phoneNumberRegex } from '@/plugins/helpers/formatPhoneNumber'
import useExtendedState from '@/plugins/helpers/useExtendedState'
import { xConsole } from '@/plugins/helpers/xConsole'

// Themes
import theme from '@/config/themes'

// Components
import SubmitButton from '@/components/login/SubmitButton'
import TermsOfService from '@/components/TermsOfService'

// Configs
import { reCaptchaApiKey } from '@/config'

// Types
import type { TosRef, ILoginScreenAuth } from '@/types'
interface PhoneFormProps {
  onSuccess: (auth: ILoginScreenAuth) => void
}
type ILoginArgs = {
  phoneNumber: string
  requestId: string
  validUntil: string
  isNewUser: boolean
  hasPassword: boolean
}

const Recaptcha = Platform.select({
  default: () => require('react-native-recaptcha-that-works').default,
  web: () => require('react-recaptcha-that-works').default,
})()

function PhoneForm({ onSuccess }: PhoneFormProps) {
  const [phoneNumber, setPhoneNumber, getPhoneNumber] = useExtendedState('')
  const [isValid, setIsValid, getIsValid] = useExtendedState(false)
  const TermsOfServiceRef = useRef<TosRef>(null)
  const [isAgreedTerms, setIsAgreedTerms, getIsAgreedTerms] = useExtendedState(false)
  const [isLoading, setIsLoading] = useState(false)
  const recaptchaRef = useRef<any>(null)
  const [recaptchaToken, setRecaptchaToken, getRecaptchaToken] = useExtendedState('')
  const [loginData, setLoginData, getLoginData] = useExtendedState<ILoginArgs | null>(null)

  // Validation
  const onChangeText = (num: string) => {
    const formattedPhoneNumber = num.replace(/ +/g, '').replace('+47', '')
    setPhoneNumber(formattedPhoneNumber)
    setIsValid(!!formattedPhoneNumber && phoneNumberRegex.test(formattedPhoneNumber))
  }

  // Submit
  const onSubmit = async () => {
    toast.hideAll()
    const isValid = await getIsValid()
    const isAgreedTerms = await getIsAgreedTerms()
    const recaptchaToken = await getRecaptchaToken()
    const phoneNumber = await getPhoneNumber()
    const loginData = await getLoginData()

    // Check if login data (after accepted terms, just login it)
    if (loginData) {
      doLogin(loginData)
      return
    }

    if (!isValid) {
      toast.show('Skriv inn riktig telefonnummer for å logge inn.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      return
    }

    if (!recaptchaToken) {
      if (Platform.OS !== 'web') {
        recaptchaRef.current?.open()
      } else {
        recaptchaRef.current?.execute()
      }
      return
    }

    try {
      setIsLoading(true)
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber)
      const res = await axios.post('/api/activation/mobile', { phoneNumber: formattedPhoneNumber, reCaptcha: recaptchaToken })
      setIsLoading(false)
      const data = {
        phoneNumber: formattedPhoneNumber,
        requestId: res.data.requestId,
        validUntil: res.data.validUntil,
        isNewUser: res.data.isNewUser,
        hasPassword: res.data.hasPassword,
      }
      setLoginData(data)

      if (res.data.termsConditionsAccepted || isAgreedTerms) {
        setIsAgreedTerms(true)
        doLogin(data)
        return
      } else {
        TermsOfServiceRef.current?.setIsActive(true)
        return
      }
    } catch (error: any) {
      setIsLoading(false)
      toast.show(error.response?.data?.detail || 'Beklager, noe gikk galt.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      xConsole().error(error, 'PhoneForm')
    }
  }

  const doLogin = (args: ILoginArgs) => {
    onSuccess({
      phoneNumber: args.phoneNumber,
      requestId: args.requestId,
      validUntil: args.validUntil,
      isNewUser: args.isNewUser,
      hasPassword: args.hasPassword,
    })
    toast.show(`Oppgi koden vi sendte til\n${args.phoneNumber}`, { type: 'main', data: { title: 'Topp!', icon: 'success' } })
  }

  return (
    <View>
      <Input
        placeholder="Fyll inn ditt mobilnummer"
        keyboardType="phone-pad"
        containerStyle={styles.inputContainer}
        inputStyle={styles.input}
        inputContainerStyle={{ borderBottomWidth: 0 }}
        placeholderTextColor={theme.colors.black}
        onChangeText={onChangeText}
        onSubmitEditing={() => onSubmit()}
        disabledInputStyle={{ opacity: 1 }}
        disabled={isLoading}
        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
        autoComplete="tel-device"
        textContentType="telephoneNumber"
        value={phoneNumber}
      />
      <SubmitButton title="LOGG INN" onPress={onSubmit} loading={isLoading} valid={isValid} />
      <CheckBox
        title={
          <Text
            style={{
              fontSize: 18,
              paddingLeft: 5,
              paddingRight: 5,
              textDecorationLine: 'underline',
            }}
            onPress={() => {
              TermsOfServiceRef.current?.setIsActive(true)
            }}
          >
            Jeg har lest og godtar vilkår
          </Text>
        }
        checked={isAgreedTerms}
        onPress={() => setIsAgreedTerms(!isAgreedTerms)}
        iconType="ionicon"
        checkedIcon={'checkmark-circle-outline'}
        uncheckedIcon={'radio-button-off'}
        uncheckedColor={theme.colors.black}
        checkedColor={theme.colors.black}
        containerStyle={{
          padding: 0,
          marginTop: 0,
          alignItems: 'center',
        }}
      />
      <TermsOfService
        testID="loginTos"
        ref={TermsOfServiceRef}
        onAgreed={() => {
          setIsAgreedTerms(true)
          onSubmit()
        }}
      />
      <Recaptcha
        ref={recaptchaRef}
        siteKey={reCaptchaApiKey}
        baseUrl="https://web.kopling.no"
        onVerify={(token: string) => {
          setRecaptchaToken(token)
          onSubmit()
        }}
        size="invisible"
        enterprise={true}
        hideBadge={true}
        action="signup"
      />
    </View>
  )
}

export default PhoneForm

const styles = StyleSheet.create({
  inputContainer: {
    height: 55,
    paddingHorizontal: 0,
  },
  input: {
    height: 55,
    fontSize: 18,
    textAlign: 'center',
    borderWidth: 1.5,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
})
