import axios, { AxiosResponse } from 'axios'

// Helpers
import isTokenExpired from '@/plugins/helpers/isTokenExpired'
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IUpdateLastseenReturn = Promise<AxiosResponse>

/*
  Update user's lastseen for checking user's availability in backend
  Example usage: Auto run interval in background like X secs
*/
async function updateLastseen(): IUpdateLastseenReturn {
  try {
    if (!isTokenExpired()) {
      const res = await axios.put('/api/profilesettings/updatelastseen')
      return res
    } else {
      throw 'TOKEN_EXPIRED'
    }
  } catch (error: any) {
    xConsole().error(error, 'updateLastseen')
    return error.response as AxiosResponse
  }
}

export default updateLastseen
