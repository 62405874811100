import getApiCurrentUser from '@/api/users/getCurrentUser'
import updateLastseen from '@/api/auth/updateLastseeen'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '@/store'
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import type { IUserState, IUser, IToken, IDevice } from '@/types'
const initialState: IUserState = {
  user: null,
  device: {
    timeOffset: 0,
  },
  token: null,
  isDevMode: false,
  isLoading: false,
  error: false,
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUser(state) {
      state.isLoading = true
      state.error = false
    },
    getUserSuccess(state, action: PayloadAction<{ user: IUser }>) {
      const payload = action.payload
      state.isLoading = false
      state.user = payload.user
    },
    getUserFailure(state) {
      state.isLoading = false
      state.error = true
    },
    setUserToken(state, action: PayloadAction<IToken>) {
      const payload = action.payload
      payload.expires_date = new Date(new Date().getTime() + payload.expires_in * 1000)
      state.token = payload
    },
    setUserDevice(state, action: PayloadAction<{ device: IDevice }>) {
      const payload = action.payload
      state.device = { ...state.device, ...payload.device }
    },
    setUserDevMode(state, action: PayloadAction<boolean>) {
      const payload = action.payload
      state.isDevMode = payload
    },
  },
})

export const { getUser, getUserSuccess, getUserFailure, setUserToken, setUserDevice, setUserDevMode } = user.actions

export default user.reducer

export const fetchUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getUser())

    // Update user's lastseen (no failling)
    try {
      updateLastseen()
    } catch (error) {}

    // Get user info
    const res = await getApiCurrentUser()
    if (res?.status === 200) {
      dispatch(getUserSuccess({ user: res.data }))

      // Get device info
      /*
      const serverTime = await getServerTime()
      dispatch(setUserDevice({ device: { timeOffset: serverTime.offset } }))
      */
    }
  } catch (error) {
    dispatch(getUserFailure())
    xConsole().error(error as Error, 'userSlice (fetchUser)')
  }
}

export const toggleDevMode = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(getUser())
    const { isDevMode } = getState().user
    dispatch(setUserDevMode(!isDevMode))
  } catch (error) {
    xConsole().error(error as Error, 'userSlice (toggleDevMode)')
  }
}
