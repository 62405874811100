import { useEffect, useState, useRef } from 'react'
import { Input } from '@rneui/themed'
import axios from 'axios'

import { Platform, StyleSheet, View, TextInput } from 'react-native'

// Theme
import theme from '@/config/themes'

// Components
import SubmitButton from '@/components/login/SubmitButton'

// Types
import type { ILoginScreenAuth } from '@/types'
import type { Input as BaseInput } from '@rneui/base'
type NameFormProps = {
  onSuccess: (auth: ILoginScreenAuth) => void
  auth: ILoginScreenAuth
}

function NameForm({ onSuccess, auth }: NameFormProps) {
  const [firstName, setFirstName] = useState(auth?.firstName || '')
  const [lastName, setLastName] = useState(auth?.firstName || '')
  const [isFirstNameValid, setIsFirstNameValid] = useState(false)
  const [isLastNameValid, setIsLastNameValid] = useState(false)
  const firstNameInputRef = useRef<TextInput & BaseInput>(null)
  const lastNameInputRef = useRef<TextInput & BaseInput>(null)

  useEffect(() => {
    if (auth && auth.firstName && auth.lastName) {
      firstNameInputRef.current?.setNativeProps({ text: auth.firstName })
      lastNameInputRef.current?.setNativeProps({ text: auth.lastName })
      onChangeFirstName(auth.firstName)
      onChangeLastName(auth.lastName)
    }
  }, [auth])
  // Validation
  const firstNameRegex = new RegExp('.{2,}$')
  const onChangeFirstName = (s: string): void => {
    setFirstName(s)
    setIsFirstNameValid(!!s && firstNameRegex.test(s))
  }
  const lastNameRegex = new RegExp('.{2,}$')
  const onChangeLastName = (s: string): void => {
    setLastName(s)
    setIsLastNameValid(!!s && lastNameRegex.test(s))
  }

  // Submit
  const onSubmit = async () => {
    toast.hideAll()
    if (!isFirstNameValid) {
      toast.show('Fornavn er obligatorisk.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      return
    }
    if (!isLastNameValid) {
      toast.show('Etternavn er obligatorisk.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      return
    }

    if (!auth) {
      return
    }

    auth.firstName = firstName
    auth.lastName = lastName
    onSuccess(auth)

    // If has token (navigate back), save it when submit
    if (auth.token) {
      try {
        axios.defaults.headers.common['Authorization'] = auth.token.access_token ? `Bearer ${auth.token.access_token}` : ''
        await axios.put('/api/user/details', { firstName: auth.firstName, lastName: auth.lastName })
      } catch (error) {}
    }
  }

  return (
    <View>
      <Input
        ref={firstNameInputRef}
        placeholder="Fornavn"
        containerStyle={styles.inputContainer}
        inputStyle={[styles.input, { textAlign: 'center' }]}
        inputContainerStyle={{ borderBottomWidth: 0 }}
        placeholderTextColor={theme.colors.black}
        onChangeText={onChangeFirstName}
        onSubmitEditing={onSubmit}
        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
      />
      <Input
        ref={lastNameInputRef}
        placeholder="Etternavn"
        containerStyle={styles.inputContainer}
        inputStyle={[styles.input, { textAlign: 'center' }]}
        inputContainerStyle={{ borderBottomWidth: 0 }}
        placeholderTextColor={theme.colors.black}
        onChangeText={onChangeLastName}
        onSubmitEditing={onSubmit}
        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
      />
      <SubmitButton title="GÅ VIDERE" onPress={onSubmit} loading={false} valid={isFirstNameValid && isLastNameValid} />
    </View>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    height: 55,
    paddingHorizontal: 0,
    marginBottom: 15,
  },
  input: {
    height: 55,
    fontSize: 18,
    textAlign: 'center',
    borderWidth: 1.5,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
})

export default NameForm
