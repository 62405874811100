import { StyleSheet, View } from 'react-native'
import { Text } from '@rneui/themed'

// Types
type TitleProps = {
  title: string
}

const Title = ({ title }: TitleProps) => {
  return (
    <View style={styles.mainContainer}>
      <Text h4 h4Style={styles.title}>
        {title}
      </Text>
    </View>
  )
}

export default Title

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    paddingHorizontal: 20,
    marginTop: 10,
    flexBasis: 'auto', // Web
  },
  title: {
    fontFamily: 'FSJoeyBold',
    fontSize: 30,
    lineHeight: 30,
  },
})
