import { ScrollView } from 'react-native'
import { Text } from '@rneui/themed'
import { SafeAreaView } from 'react-native-safe-area-context'
import TabMenus from '@/components/TabMenus'

// Components
import TopNavbar from '@/components/contents/Main/TopNavbar'
import FeaturedImage from '@/components/contents/Main/FeaturedImage'
import ArticleTitle from '@/components/contents/Main/ArticleTitle'
import FAQList from '@/components/faq/List'
import UngWidget from '@/components/faq/widgets/Ung'

let FAQScreen = () => {
  return (
    <SafeAreaView edges={['bottom', 'left', 'right']} style={{ flexDirection: 'column', flex: 1 }}>
      <TopNavbar goBack />
      <ScrollView>
        <FeaturedImage src={''} />
        <ArticleTitle title={'Hva lurer du på?'} />
        <Text style={{ marginHorizontal: 20, fontSize: 20 }}>Her ligger det svar på mange spørsmål. Kanskje du finner svar på ditt?</Text>
        <FAQList />
        <UngWidget />
      </ScrollView>
      <TabMenus />
    </SafeAreaView>
  )
}

export default FAQScreen
