import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { koplingConfig } from '@/config'
import axios from 'axios'
import { defaultsDeep } from 'lodash'
import { REHYDRATE } from 'redux-persist'

// API
import getTokenExchange from '@/api/auth/getTokenExchange'

// Types
import type { RootState } from '@/store/rootReducer'
import { IInstitution } from '@/types'

// getPages
interface IGetPagesValueResponse {
  [key: string]: any
}
interface IGetPagesResponse {
  data: {
    queryPagesContents: IGetPagesValueResponse
  }
}
export type IGetPagesArgs = {
  targetGroups: Array<string>
  categories?: Array<string>
  ids?: Array<string>
  orderBy?: string
}

// getCrisisroutine
interface IGetCrisisroutineValueResponse {
  [key: string]: any
}
interface IGetCrisisroutineResponse {
  content: IGetCrisisroutineValueResponse
}
// getPrivacypolicy
interface IGetPrivacypolicyValueResponse {
  [key: string]: any
}
interface IGetPrivacypolicyResponse {
  content: IGetPrivacypolicyValueResponse
}

// getBullyButtonsPage
interface IGetBullyButtonsPageValueResponse {
  [key: string]: any
}
interface IGetBullyButtonsPageResponse {
  data: {
    queryBullybuttonsContents: IGetBullyButtonsPageValueResponse
  }
}
export type IGetBullyButtonsPageArgs = {
  targetGroups: Array<string>
}

export const contentApi = createApi({
  reducerPath: 'contentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: koplingConfig.contentApiUrl,
  }),
  tagTypes: ['Content', 'UserMetaData'],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE && action.payload) {
      return action.payload[reducerPath]
    }
  },
  endpoints: (builder) => ({
    /**
     * ::::: Get pages (Squidex) :::::
     * @apiUrl [POST] /api/content/app/graphql
     * @example
     * import { contentApi } from '@/slices/contentsSlice'
     * 
     * const { isLoading, isFetching, data: pagesData } = contentApi.endpoints.getPages.useQuery({}, {
        skip: false,
        refetchOnMountOrArgChange: true,
      })
    */
    getPages: builder.query<IGetPagesValueResponse, IGetPagesArgs>({
      query(args) {
        // Filter (Target groups)
        const filterTargetGroups = args.targetGroups.length ? args.targetGroups.map((v) => `'${v}'`).join(',') : `'all'`
        const filters = [`data/targetGroups/iv in (${filterTargetGroups})`]

        // Filter (Categories)
        const filterCategories = args.categories?.length ? args.categories.map((v) => `'${v}'`).join(', ') : ''
        if (filterCategories) {
          filters.push(`data/categories/iv in (${filterCategories})`)
        }

        // Filter (Ids)
        const filterIds = args.ids?.length ? args.ids.map((v) => `'${v}'`).join(', ') : ''
        if (filterIds) {
          filters.push(`id in (${filterIds})`)
        }

        // Filters to string
        const filterString = filters.join(' and ')

        // OrderBy
        const orderByString = args.orderBy !== undefined ? args.orderBy : 'data/priority/iv desc,created desc'

        return {
          url: '/api/content/app/graphql',
          method: 'POST',
          body: {
            query: `
              {queryPagesContents(
                filter: "${filterString}",
                orderby: "${orderByString}"
              ) {
                id,
                flatData {
                  title,
                  excerpt,
                  thumbnailImage { url },
                  featuredImage { url },
                  fullWidth
                }
              }}
            `,
          },
        }
      },
      transformResponse: (result: IGetPagesResponse) => result.data.queryPagesContents ?? [],
    }),
    /**
     * ::::: Get crisisroutine :::::
     * @apiUrl [GET] /api/profilesettings/crisisroutine
     * @example
     * import { contentApi } from '@/slices/contentsSlice'
     * 
     * const { isLoading, isFetching, data: contentData } = contentApi.endpoints.getCrisisroutine.useQuery(undefined, {
        skip: false,
        refetchOnMountOrArgChange: true,
      })
    */
    getCrisisroutine: builder.query<IGetCrisisroutineValueResponse, void>({
      query() {
        return {
          url: `${koplingConfig.apiUrl}/api/profilesettings/crisisroutine`,
        }
      },
      transformResponse: (result: IGetCrisisroutineResponse) => result.content ?? '…',
    }),

    /**
     * ::::: Get privacypolicy :::::
     * @apiUrl [GET] /api/profilesettings/privacypolicy
     * @example
     * import { contentApi } from '@/slices/contentsSlice'
     * 
     * const { isLoading, isFetching, data: contentData } = contentApi.endpoints.getPrivacypolicy.useQuery(undefined, {
        skip: false,
        refetchOnMountOrArgChange: true,
      })
    */
    getPrivacypolicy: builder.query<IGetPrivacypolicyValueResponse, void>({
      query() {
        return {
          url: `${koplingConfig.apiUrl}/api/profilesettings/privacypolicy`,
        }
      },
      transformResponse: (result: IGetPrivacypolicyResponse) => result.content ?? '…',
    }),

    /**
     * ::::: Get current user meta data (Mock for now) :::::
     * @example
     * import { contentApi } from '@/slices/contentsSlice'
     * 
     * const { data: userMetaData } = contentApi.endpoints.userMetaData.useQuery(undefined, {
        skip: false,
        refetchOnMountOrArgChange: true,
      })
    */
    userMetaData: builder.query<{ [key: string]: any }, void>({
      queryFn: async (arg, api) => {
        try {
          const state = api.getState() as RootState
          const res = await axios({
            url: `${koplingConfig.apiUrl}/api/usermetadata/metadata`,
            headers: {
              Authorization: `Bearer ${state.user?.token?.access_token}`,
            },
          })
          if (res.status !== 200 || !res.data?.metaData) {
            return { data: {} }
          }
          return { data: res.data.metaData }
        } catch {
          return { data: {} }
        }
      },
      providesTags: ['UserMetaData'],
    }),

    /**
     * ::::: Edit current user meta data (Mock for now) :::::
     * @example
     * import { contentApi } from '@/slices/contentsSlice'
     * 
     * const [triggerUserMetaData] = contentApi.endpoints.editUserMetaData.useMutation()
      await triggerUserMetaData({ ...data })
    */
    editUserMetaData: builder.mutation<string | null, Partial<{ key: string; value: any }>>({
      queryFn: async (arg, api) => {
        try {
          const state = api.getState() as RootState
          const { key, value } = arg
          let formattedValue = value
          const cache = contentApi.endpoints.userMetaData.select()(api.getState() as RootState)?.data ?? {}

          if (key === 'cafeteriaCards') {
            const prevCafeteriaCards = cache[key] ?? {}
            formattedValue = defaultsDeep(value, prevCafeteriaCards)
          }

          const res = await axios({
            url: `${koplingConfig.apiUrl}/api/usermetadata/create`,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${state.user?.token?.access_token}`,
            },
            data: {
              metaData: {
                [key as string]: formattedValue,
              },
              merge: true,
            },
          })
          return { data: res.data }
        } catch {
          return { status: 400, data: null }
        }
      },
      invalidatesTags: ['UserMetaData'],
    }),

    resetUserMetaData: builder.mutation<string | null, void>({
      queryFn: async (arg, api) => {
        try {
          const state = api.getState() as RootState

          const res = await axios({
            url: `${koplingConfig.apiUrl}/api/usermetadata/create`,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${state.user?.token?.access_token}`,
            },
            data: {
              metaData: {},
            },
          })
          return { data: res.data }
        } catch {
          return { status: 400, data: null }
        }
      },
      invalidatesTags: ['UserMetaData'],
    }),

    /**
     * ::::: Get current user cafeterias by selected institutions :::::
     * @example
     * import { contentApi } from '@/slices/contentsSlice'
     * 
     * const { data: userCafeteriasData } = contentApi.endpoints.userCafeterias.useQuery(undefined, {
        skip: false,
        refetchOnMountOrArgChange: true,
      })
    */
    userCafeterias: builder.query<IInstitution[], void>({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        try {
          const state = api.getState() as RootState
          const institutions = state.user?.user?.institutions ?? []
          const result: IInstitution[] = []
          const res = await axios({
            url: `${koplingConfig.apiUrl}/api/institution/list?per_page=1000&page=1&isActive=true`,
            headers: {
              Authorization: `Bearer ${state.user?.token?.access_token}`,
            },
          })
          if (res.status === 200) {
            for (const institution of institutions) {
              const data = res.data.find((x: IInstitution) => x.institutionId === institution.institutionId)

              if (data) {
                // Push data
                result.push({
                  institutionId: data.institutionId,
                  institutionName: data.institutionName,
                  description: data.description ?? '',
                  linkURL: data.linkURL ?? '',
                  countyId: data.countyId ?? null,
                  isCounty: data.isCounty ?? false,
                  isNational: data.isNational ?? false,
                  pejShopId: data.pejShopId,
                })
              }
            }
          }

          return { data: result }
        } catch {
          return { data: [] }
        }
      },
    }),
    /**
     * ::::: Get current user cafeteria's balance :::::
     * @example
     * 
     * import { contentApi } from '@/slices/contentsSlice'
     * 
     * const { data: userCafeteriaBalanceData } = contentApi.endpoints.userCafeteriaBalance.useQuery('123', {
        skip: false,
        refetchOnMountOrArgChange: true,
      })
    */
    userCafeteriaBalance: builder.query<number | null, string>({
      queryFn: async (shopId, api, extraOptions, baseQuery) => {
        try {
          let result = null
          const tokenExchange = await getTokenExchange()

          if (tokenExchange?.data?.access_token) {
            const res = await axios.get(`${koplingConfig.pejApiUrl}/shops/${shopId}/fsgm/balance`, {
              headers: {
                'Et-Token': tokenExchange.data.access_token,
              },
            })
            result = res.data?.balances?.[0]?.amount ?? null
          }

          return { data: result }
        } catch (error) {
          return { data: null }
        }
      },
    }),

    getBullyButtonsPage: builder.query<IGetBullyButtonsPageValueResponse, IGetBullyButtonsPageArgs>({
      query(args) {
        // Filter (Target groups)
        const filterTargetGroups = args.targetGroups.length ? args.targetGroups.map((v) => `'${v}'`).join(',') : `'all'`
        const filters = [`data/targetGroups/iv in (${filterTargetGroups})`]

        // Filters to string
        const filterString = filters.join(' and ')

        return {
          url: '/api/content/app/graphql',
          method: 'POST',
          body: {
            query: `
              {queryBullybuttonsContents(
                filter: "${filterString}",
              ) {
                id,
                flatData {
                  title,
                  pages {
                    id,
                    flatData {
                      title,
                      thumbnailImage { url }
                    }
                  },
                  users {
                    title,
                    items
                  }
                }
              }}
            `,
          },
        }
      },
      transformResponse: (result: IGetBullyButtonsPageResponse) => result.data.queryBullybuttonsContents[0] ?? null,
    }),
  }),
})

export const { useGetPagesQuery, useGetCrisisroutineQuery, useGetPrivacypolicyQuery } = contentApi
