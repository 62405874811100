import React from 'react'
import { LogBox, Platform, Text, TextInput } from 'react-native'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import store, { persistor } from './src/store'
import AppNavigator, { navigationRef } from './src/navigation/AppNavigator'
import { NavigationContainer, DefaultTheme } from '@react-navigation/native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import * as Sentry from '@sentry/react-native'

import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { GestureHandlerRootView } from 'react-native-gesture-handler'

import 'moment/locale/nb'

import { injectStore } from './src/config'

// Theme
import { useFonts } from 'expo-font'
import { ThemeProvider, createTheme } from '@rneui/themed'
import { StatusBar } from 'expo-status-bar'
import theme from './src/config/themes'

// Contexts
import { AuthProvider } from '@/contexts/auth'
import { ACSProvider } from '@/contexts/acs'
import { NotificationsProvider } from '@/contexts/notifications'
import { AppInsightsProvider } from '@/contexts/appInsights'

// Components
import EmergencyMessage from '@/components/EmergencyMessage'
import DataRefresher from '@/components/DataRefresher'

// import AlertMessage from '@/components/AlertMessage'
import ToastMain from '@/components/toasts/Main'
import ToastCenter from '@/components/toasts/Center'
import ToastNewMessage from '@/components/toasts/NewMessage'
import ToastNetworkStatus from '@/components/toasts/NetworkStatus'

// Toast
import Toast from 'react-native-toast-notifications'

// Ignore logs
LogBox.ignoreLogs([
  'AsyncStorage has been extracted from react-native core',
  'componentWillMount has been renamed',
  'componentWillReceiveProps has been renamed',
  'Setting a timer',
  'RCTDevLoadingView',
  'no-image',
  'Image source "null"',
  'Constants.platform.ios.model',
  'onAnimatedValueUpdate',
])

// Variables
const RNEUItheme = createTheme(theme)

// Disallow font scaling
Text.defaultProps = Text.defaultProps || {}
Text.defaultProps.allowFontScaling = false
TextInput.defaultProps = TextInput.defaultProps || {}
TextInput.defaultProps.allowFontScaling = false

const navigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: theme.colors.bodybg,
  },
}

injectStore(store)

if (!global.sentryInit) {
  Sentry.init({
    dsn: 'https://e6f124786b964750a517a935119fe397@o1258944.ingest.sentry.io/6433311',
  })
  global.sentryInit = true
}

const App = () => {
  // Load fonts
  const [fontsLoaded] = useFonts({
    FSJoey: require('./src/assets/fonts/FSJoey-Regular.otf'),
    FSJoeyMedium: require('./src/assets/fonts/FSJoey-Medium.otf'),
    FSJoeyBold: require('./src/assets/fonts/FSJoey-Bold.otf'),
    AntDesign: require('react-native-vector-icons/Fonts/AntDesign.ttf'),
    FontAwesome5_Solid: require('react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf'),
    MaterialIcons: require('react-native-vector-icons/Fonts/MaterialIcons.ttf'),
    MaterialCommunityIcons: require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
    Entypo: require('react-native-vector-icons/Fonts/Entypo.ttf'),
    Fontisto: require('react-native-vector-icons/Fonts/Fontisto.ttf'),
    Feather: require('react-native-vector-icons/Fonts/Feather.ttf'),
    Ionicons: require('react-native-vector-icons/Fonts/Ionicons.ttf'),
    Octicons: require('react-native-vector-icons/Fonts/Octicons.ttf'),
  })

  if (!fontsLoaded) {
    return null
  } // Wait for Auth init and fonts load

  return (
    <Provider {...{ store }}>
      <PersistGate loading={null} {...{ persistor }}>
        <AuthProvider>
          <NotificationsProvider navigation={navigationRef}>
            <AppInsightsProvider navigation={navigationRef}>
              <ACSProvider navigation={navigationRef}>
                <ThemeProvider theme={RNEUItheme}>
                  <GestureHandlerRootView style={{ flex: 1 }}>
                    <ActionSheetProvider>
                      <SafeAreaProvider>
                        <NavigationContainer theme={navigationTheme} ref={navigationRef}>
                          <BottomSheetModalProvider>
                            <AppNavigator />
                            <EmergencyMessage />
                            <StatusBar style="dark" />
                            <DataRefresher navigation={navigationRef} />
                          </BottomSheetModalProvider>
                        </NavigationContainer>
                      </SafeAreaProvider>
                    </ActionSheetProvider>
                  </GestureHandlerRootView>
                </ThemeProvider>
              </ACSProvider>
            </AppInsightsProvider>
          </NotificationsProvider>
        </AuthProvider>
      </PersistGate>
      <Toast
        ref={(ref) => ((global as any)['toast'] = ref)}
        placement="top"
        offset={40}
        normalColor={'rgba(40,40,40,0.85)'}
        textStyle={{ fontFamily: 'FSJoey', fontSize: 14, lineHeight: 14 }}
        animationType="zoom-in"
        swipeEnabled={Platform.OS !== 'android'}
        renderType={{
          main: (toast) => <ToastMain toast={toast} />,
          center: (toast) => <ToastCenter toast={toast} />,
          newMessage: (data) => <ToastNewMessage data={data} navigation={navigationRef} />,
          networkStatus: (toast) => <ToastNetworkStatus toast={toast} />,
        }}
      ></Toast>
    </Provider>
  )
}

export default Sentry.wrap(App)
