import axios, { AxiosResponse } from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IAcceptTermsConditionsArgs = {
  accept: boolean
  accessToken?: string
}
export type IAcceptTermsConditionsReturn = Promise<AxiosResponse>

/**
 * Accept terms and conditions
 *
 * @param  { IAcceptTermsConditionsArgs } args (IAcceptTermsConditionsArgs)
 * @returns { IAcceptTermsConditionsReturn } Promise<AxiosResponse>
 * @example
 * import acceptTermsConditions from '@/api/auth/acceptTermsConditions'
 * // Usage:
 * await acceptTermsConditions({ accept: true, accessToken: '…' })
 */
async function acceptTermsConditions(args: IAcceptTermsConditionsArgs): IAcceptTermsConditionsReturn {
  try {
    const headers = {}
    if (args.accessToken) {
      headers['Authorization'] = `Bearer ${args.accessToken}`
    }
    const res = await axios.put(`/api/user/accepttermsconditions?accept=${String(args.accept)}`, null, { headers })
    return res
  } catch (error: any) {
    xConsole().error(error, 'acceptTermsConditions')
    return error.response
  }
}

export default acceptTermsConditions
