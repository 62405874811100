import { useState, useCallback } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { Text, Divider } from '@rneui/themed'
import { koplingConfig } from '@/config'

// APIs
import { contentApi } from '@/slices/contentsSlice'

// Themes
import theme from '@/config/themes'

// Types
import type { ICafeteriaWebviewRef } from '@/components/cafeterias/Webview'
type BalanceButtonProps = {
  cafeteriaWebviewRef: ICafeteriaWebviewRef | null
  shopId: string
}
const BalanceButton = ({ cafeteriaWebviewRef, shopId }: BalanceButtonProps) => {
  const { data: userCafeteriaBalanceData, refetch: refetchCafeteriaBalanceData } = contentApi.endpoints.userCafeteriaBalance.useQuery(shopId, {
    skip: false,
    refetchOnMountOrArgChange: true,
  })
  const formattedBalance = (v: number) => {
    return v % 100 === 0 ? v / 100 : (v / 100).toFixed(2).replace('.', ',')
  }
  return (
    <Pressable
      style={[styles.itemContainer]}
      onPress={() => {
        refetchCafeteriaBalanceData()
        cafeteriaWebviewRef?.setUrl(`${koplingConfig.pejUrl}/${shopId}/wallet?scheme=elevtjenesten`)
        cafeteriaWebviewRef?.setIsActive(true)
      }}
    >
      <View style={{ marginTop: 12 }}>
        {userCafeteriaBalanceData >= 0 ? (
          <Text>
            <Text style={styles.itemBalance}>{formattedBalance(userCafeteriaBalanceData)}</Text>
            <Text style={styles.itemCurrency}>kr</Text>
          </Text>
        ) : (
          <Text style={styles.itemBalance}>…</Text>
        )}
      </View>
      <Divider width={5} color={theme.colors.primary} style={{ width: '80%', borderRadius: 10, marginTop: 5, marginBottom: 10 }} />
      <Text style={styles.itemTitle}>Saldo</Text>
    </Pressable>
  )
}

export default BalanceButton

const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e9f8c9',
    borderRadius: 8,
    padding: 10,
    margin: 5,
  },
  itemBalance: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 25,
  },
  itemCurrency: {
    fontFamily: 'FSJoeyBold',
    fontSize: 13,
  },

  itemTitle: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 14,
  },
})
