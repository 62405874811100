import { createContext, useCallback, useContext, useEffect } from 'react'
import { AppState, Platform } from 'react-native'
import * as LocalAuthentication from 'expo-local-authentication'
import axios from 'axios'
import moment from 'moment'
import { debounce } from 'lodash'

// Store
import store from '@/store'

// Configs
import { koplingConfig } from '@/config'

// APIs
import getToken, { IGetTokenArgs, IGetTokenReturn } from '@/api/auth/getToken'
import verifyToken, { IVerifyTokenArgs, IVerifyTokenReturn } from '@/api/auth/verifyToken'
import refreshToken, { IRefreshTokenArgs, IRefreshTokenReturn } from '@/api/auth/refreshToken'
import logout, { ILogoutReturn } from '@/api/auth/logout'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { setUserToken } from '@/slices/userSlice'
import { fetchUser } from '@/slices/userSlice'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'
import sendDataToSlack from '@/plugins/helpers/sendDataToSlack'
import useExtendedState from '@/plugins/helpers/useExtendedState'

// Axios (setup)
axios.defaults.baseURL = koplingConfig.apiUrl

const MAX_ATTEMPTS = 10
const EXPIRES_DIFF_UNIT = 'days'
const EXPIRES_DIFF_TO_REFRESH = 5
const EXPIRES_DIFF_TOO_OLD = -1

// Types
import type { IToken, IUser } from '@/types'
type AuthContextData = {
  checkAuthState(token: IToken | null, user: IUser | null): void
  getToken(args: IGetTokenArgs): IGetTokenReturn
  refreshToken(args: IRefreshTokenArgs): IRefreshTokenReturn
  verifyToken(args: IVerifyTokenArgs): IVerifyTokenReturn
  logout(): ILogoutReturn
  isBiometricAAuthenticationSupported(): Promise<boolean>
  requestBiometricAAuthentication(): void
}
type AuthProps = {
  children: React.ReactNode
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: React.FC<AuthProps> = ({ children }) => {
  const dispatch = useDispatch()
  const { user, token } = useSelector((state: RootState) => state.user)
  const [userLoggedOutLogs, setUserLoggedOutLogs, getUserLoggedOutLogs] = useExtendedState<Array<string>>([])
  const [checkAuthStateAttempted, setCheckAuthStateAttempted, getCheckAuthStateAttempted] = useExtendedState(0)

  useEffect(() => {
    checkAuthState()
    const listener = AppState.addEventListener('change', checkAuthState)
    return () => listener.remove()
  }, [token?.access_token])

  const sendLoggoutDataToSlack = useCallback(
    async (reason: string) => {
      setUserLoggedOutLogs((prev) => [...prev, reason])
      setUserLoggedOutLogs((prev) => [...prev, JSON.stringify(user)])
      const data = await getUserLoggedOutLogs()
      if (!__DEV__) {
        sendDataToSlack({ title: '🔥 User logged out (version 6):\n', data: data.join('\n') })
      }
      setUserLoggedOutLogs([])
    },
    [user]
  )

  const shouldLogout = async (force = false) => {
    try {
      const user = store.getState().user
      const token = user?.token
      const expiresDiff = moment(token.expires_date).diff(new Date(), EXPIRES_DIFF_UNIT)

      // Auto logout is not stable internet connection and token is too old
      if (!(await isStableInternetConnection()) && expiresDiff <= EXPIRES_DIFF_TOO_OLD) {
        sendLoggoutDataToSlack(`🔥 LOGOUT: Token too old.`)
        dispatch({ type: 'CLEAR_ALL' })
        return false
      }

      // Force logout
      if (force) {
        dispatch({ type: 'CLEAR_ALL' })
        return false
      }
    } catch (error) {}
  }

  const isStableInternetConnection = async () => {
    try {
      await axios.get('/api/user/profile', { validateStatus: (s) => s < 500, timeout: 1000 })
      return true
    } catch (error) {
      return false
    }
  }

  const checkAuthState = debounce(
    async () => {
      // Check is stable internet connection before running…
      if (!(await isStableInternetConnection())) {
        toast?.hideAll()
        toast?.show('Du virker å være offline for øyeblikket.', { type: 'networkStatus', animationDuration: 0, swipeEnabled: false, duration: 5000 })
        await new Promise((r) => setTimeout(r, 3000))
        shouldLogout()
        checkAuthState()
        return false
      }

      try {
        const user = store.getState().user
        const token = user?.token

        setCheckAuthStateAttempted((prev) => prev + 1)
        const attempted = await getCheckAuthStateAttempted()

        if ((!user || !token) && attempted >= MAX_ATTEMPTS) {
          sendLoggoutDataToSlack(`🔥 LOGOUT: (!user || !token) && attempted >= ${MAX_ATTEMPTS}`)
          shouldLogout(true) // ⛔
          setCheckAuthStateAttempted(0)
          return false
        }

        if (token) {
          axios.defaults.headers.common['Authorization'] = token.access_token ? `Bearer ${token.access_token}` : ''

          // Check expires
          const expiresDiff = moment(token.expires_date).diff(new Date(), EXPIRES_DIFF_UNIT)
          const isExpired = expiresDiff <= EXPIRES_DIFF_TO_REFRESH
          if (isExpired) {
            setUserLoggedOutLogs((prev) => [...prev, `CONDITION: isExpired (token expired)`])
            try {
              const res = await refreshToken(token.refresh_token)
              if (res?.status === 200) {
                dispatch(setUserToken(res.data))
                return false // Return false because this will auto-run by useEffect token
              } else {
                setUserLoggedOutLogs((prev) => [...prev, `refreshToken catch (else): ${JSON.stringify(res.data)}`])
              }
            } catch (error) {}

            if (attempted >= MAX_ATTEMPTS) {
              sendLoggoutDataToSlack(`🔥 LOGOUT: isExpired && Catch refreshToken`)
              shouldLogout(true) // ⛔
              return false
            }
            await new Promise((r) => setTimeout(r, 3000))
            checkAuthState()
            return false
          }

          dispatch(fetchUser())

          // Verify token
          try {
            axios.defaults.headers.common['Authorization'] = token.access_token ? `Bearer ${token.access_token}` : ''
            await axios.get('/api/user/profile', { timeout: 5000 })
            setCheckAuthStateAttempted(0)
          } catch (error: any) {
            if (attempted <= MAX_ATTEMPTS) {
              await new Promise((r) => setTimeout(r, 3000))
              checkAuthState()
              return false
            }
            sendLoggoutDataToSlack(`🔥 LOGOUT: verify token`)
            shouldLogout(true) // ⛔
          }
        } else {
          if (user) {
            shouldLogout(true) // ⛔
          }
        }
      } catch (error: any) {
        xConsole().error(error, 'checkAuth')
      }
    },
    3000,
    { leading: true, trailing: false }
  )

  const isBiometricAAuthenticationSupported = async () => {
    return !!(await LocalAuthentication.hasHardwareAsync()) && Platform.OS !== 'web'
  }

  const requestBiometricAAuthentication = async () => {
    console.log(isBiometricAAuthenticationSupported())
  }

  return (
    <AuthContext.Provider
      value={{ checkAuthState, getToken, refreshToken, verifyToken, logout, isBiometricAAuthenticationSupported, requestBiometricAAuthentication }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export { AuthContext, AuthProvider, useAuth }
