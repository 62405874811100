import { View, StyleSheet, Dimensions } from 'react-native'
import YoutubePlayer from 'react-native-youtube-iframe'

// Types
type FeaturedYoutubeProps = {
  id: string
}

const { width } = Dimensions.get('window')

const FeaturedYoutube = ({ id }: FeaturedYoutubeProps) => {
  return (
    <View style={styles.mainContainer}>
      <YoutubePlayer height={(width - 40) * 0.5625} videoId={id} initialPlayerParams={{ color: 'white' }} webViewStyle={{ opacity: 0.99 }} />
    </View>
  )
}

export default FeaturedYoutube

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
})
