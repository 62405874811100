import { useEffect, useState, useCallback } from 'react'
import { StyleSheet, StyleProp, ViewStyle } from 'react-native'

import { SectionGrid } from 'react-native-super-grid'
import { useFocusEffect } from '@react-navigation/native'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// APIs
import getApiOrganizations from '@/api/contents/getOrganizations'

// Components
import ItemContent from '@/components/contacts/organizations/ItemContent'

// Types
interface ListOrganizationsProps {
  filterCategories: Array<string>
}

const ListOrganizations = ({ filterCategories }: ListOrganizationsProps) => {
  const { user } = useSelector((state: RootState) => state.user)
  const [sections, setSections] = useState<any>([])
  const [filteredSections, setFilteredSections] = useState([])

  if (!user) return <></>

  const [isLoading, setIsLoading] = useState(false)

  useFocusEffect(
    useCallback(() => {
      getContents()
    }, [])
  )

  const doFilterCategories = useCallback(
    (categories: Array<string>) => {
      const result: any = []
      if (categories.length) {
        const contents: any = []
        for (const section of sections) {
          for (const data of section.data) {
            if (data.data.categories?.some((v) => categories.includes(v))) {
              contents.push(data)
            }
          }
        }
        if (contents.length) {
          contents.forEach((v, i) => {
            // Check if secondary background by index
            v.data.isSecondaryBackground = i % 4 === 1 || i % 4 === 2
          })
          result.push({
            title: 'contents',
            data: contents,
          })
        }
      }
      setFilteredSections(result)
    },
    [sections]
  )

  useEffect(() => {
    doFilterCategories(filterCategories)
  }, [filterCategories])

  const getContents = useCallback(async () => {
    try {
      setIsLoading(true)
      const _sections: any = []
      const _contents: any = []
      const res = await getApiOrganizations()

      res.data.forEach((v: any, i: number) => {
        // Check logo is horizontal format
        if (v.flatData.logo && v.flatData.logo[0]) {
          v.flatData.logo[0].isHorizontal = v.flatData.logo[0].pixelWidth > v.flatData.logo[0].pixelHeight
        }

        // Check if secondary background by index
        v.flatData.isSecondaryBackground = i % 4 === 1 || i % 4 === 2

        _contents.push({ id: v.id, data: v.flatData })
      })

      // Pre rest contents
      _sections.push({
        title: 'contents',
        data: _contents,
      })

      // Save data
      setSections(_sections)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }, [user.userId])

  const renderItem = useCallback(({ item, section }) => {
    if (section.title === 'contents') {
      return <ItemContent data={item} />
    }
    return <></>
  }, [])

  return (
    <SectionGrid
      style={styles.mainContainer}
      spacing={0}
      maxItemsPerRow={2}
      additionalRowStyle={styles.additionalRow}
      sections={filteredSections.length ? filteredSections : sections}
      renderItem={renderItem}
      stickySectionHeadersEnabled={false}
      contentContainerStyle={{ paddingBottom: 80 }}
      keyExtractor={(item, rowItemIndex) => item.id}
    />
  )
}

export default ListOrganizations

const styles = StyleSheet.create({
  mainContainer: {
    flex: 2,
    flexFlow: 'column wrap',
    paddingBottom: 100,
  },
  additionalRow: {
    marginTop: 0,
  },
})
