import { StyleSheet } from 'react-native'
import { ListItem, Icon } from '@rneui/themed'
import theme from '@/config/themes'

// Types
import { ICounty } from '@/types'
type CountySelectorItemProps = {
  item: ICounty
  active: boolean
  onPress: (active: boolean, id: string) => void
  loading: boolean
}

const CountySelectorItem = ({ item, active, onPress, loading }: CountySelectorItemProps) => {
  return (
    <ListItem
      key={item.countyId}
      onPress={() => {
        onPress(!active, item.countyId)
      }}
      containerStyle={[styles.itemContainer, { backgroundColor: active ? '#eaeaea' : '#f7f7f7' }]}
      testID="CountySelectorItem"
      disabled={loading}
    >
      <ListItem.Content>
        <ListItem.Title style={styles.itemTitle}>{item.countyName}</ListItem.Title>
      </ListItem.Content>
      {active ? (
        <Icon name="check-circle-outline" type="octicons" color={theme.colors.primary} />
      ) : (
        <Icon name="radio-button-off" type="ionicons" color={theme.colors.black} />
      )}
    </ListItem>
  )
}

export default CountySelectorItem

const styles = StyleSheet.create({
  itemContainer: {
    paddingHorizontal: 15,
    marginBottom: 10,
    marginHorizontal: 15,
    borderRadius: 10,
  },
  itemTitle: {
    fontFamily: 'FSJoey',
    fontSize: 18,
  },
})
