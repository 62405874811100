import { StyleSheet } from 'react-native'
import { InputToolbar } from 'react-native-gifted-chat'

function ChatInput(props: any) {
  const { isAnonymous } = props
  return (
    <InputToolbar
      {...props}
      containerStyle={[styles.container, { backgroundColor: !isAnonymous ? '#f8f8f8' : '#333333' }]}
      placeholderTextColor={!isAnonymous ? '#2b2c2b' : '#ffffff'}
    />
  )
}

export default ChatInput

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 0,
    paddingVertical: 5,
    backgroundColor: 'transparent',
    paddingHorizontal: 10,
  },
})
