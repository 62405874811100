import { View, StyleSheet } from 'react-native'

// Components
import { Text, Icon, Image, ListItem } from '@rneui/themed'

// Themes
import theme from '@/config/themes'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Types
import type { RootNavigatorParamList } from '@/types'
import { Routes } from '@/config/routes'
type OrganizationsProps = {
  data: any
}

const Item = ({ data }) => {
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const onPress = () => {
    navigation.navigate(Routes.OrganizationContactsPage, {
      id: data.id,
    })
  }
  return (
    <ListItem onPress={onPress} bottomDivider containerStyle={[styles.itemContainer]}>
      {data?.flatData.logo?.[0]?.url && (
        <Image source={{ uri: data?.flatData.logo?.[0]?.url }} containerStyle={styles.itemImageContainer} style={styles.itemImage} />
      )}
      <ListItem.Content>
        <ListItem.Title numberOfLines={1}>{data?.flatData?.title}</ListItem.Title>
        {data?.flatData?.shortName && (
          <ListItem.Subtitle numberOfLines={1} style={{ color: theme.colors?.grey2 }}>
            {data?.flatData?.shortName}
          </ListItem.Subtitle>
        )}
      </ListItem.Content>
      <Icon type="entypo" name="chevron-right" color={theme.colors.primary} />
    </ListItem>
  )
}

const Organizations = ({ data }: OrganizationsProps) => {
  if (!data) return null
  return (
    <View style={styles.mainContainer}>
      {data.map((v: any, i: number) => (
        <View key={i}>
          <Text style={{ fontFamily: 'FSJoeyMedium', fontSize: 18, marginBottom: 10 }}>{v.title}</Text>
          {v.items.map((v: any, i: number) => (
            <Item key={`${v.id}-${i}`} data={v} />
          ))}
        </View>
      ))}
    </View>
  )
}

export default Organizations

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexBasis: 'auto', // Web
    marginVertical: 10,
    marginHorizontal: 20,
  },

  itemContainer: {
    paddingHorizontal: 20,
    marginBottom: 10,
    backgroundColor: theme.colors.white,
    borderRadius: 8,
    height: 73,
  },
  itemImageContainer: {
    width: 50,
    aspectRatio: 1,
  },
  itemImage: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
})
