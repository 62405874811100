import { View } from 'react-native'
import { Text } from '@rneui/themed'

// Components
import ScreenSubpageLayout from '@/components/settings/ScreenSubpageLayout'
import Availability from '@/components/settings/Availability'

const SettingsAvailabilityScreen = () => {
  return (
    <>
      <ScreenSubpageLayout title="Tilgjengelighet">
        <View style={{ marginTop: 30, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>Oppdater din status i appen.</Text>
          <View style={{ marginTop: 40, marginBottom: 20 }}>
            <Availability />
          </View>
        </View>
      </ScreenSubpageLayout>
    </>
  )
}

export default SettingsAvailabilityScreen
