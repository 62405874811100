import { View, StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ScrollView } from 'react-native-gesture-handler'
import { Image, ListItem, Icon } from '@rneui/themed'

import TabMenus from '@/components/TabMenus'

// Components
import TopNavbar from '@/components/contents/Main/TopNavbar'
import FeaturedImage from '@/components/contents/Main/FeaturedImage'
import ArticleTitle from '@/components/contents/Main/ArticleTitle'
import Users from '@/components/contents/Main/Users'

// Theme
import theme from '@/config/themes'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Variables
const HELPER_IDS = ['aDv0MJLLIVeWnoq2nIeer', 'CWNHTNj9mEdkhUI15hTPu']

// Types
import type { NativeStackScreenProps } from '@react-navigation/native-stack'
import type { RootNavigatorParamList } from '@/types'
import { Routes } from '@/config/routes'
type BullyScreenProps = NativeStackScreenProps<RootNavigatorParamList, 'BullyScreen'>

let BullyScreen = ({ route }: BullyScreenProps) => {
  const { data } = route.params

  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()

  if (!data) {
    setTimeout(() => {
      navigation.navigate(Routes.Contents, {})
    }, 1000)
    return null
  }

  return (
    <SafeAreaView edges={['bottom', 'left', 'right']} style={{ flexDirection: 'column', flex: 1 }}>
      <TopNavbar goBack />

      <ScrollView>
        <FeaturedImage src={''} />
        <ArticleTitle title={data.flatData.title} />
        {data.flatData?.pages && (
          <View style={{ marginTop: 10 }}>
            {data.flatData?.pages.map((v: any, i: number) => (
              <ListItem
                key={i}
                onPress={() =>
                  navigation.navigate(Routes.ContentPage, {
                    id: v.id,
                  })
                }
                bottomDivider
                containerStyle={[styles.itemListContainer, { backgroundColor: theme.colors.white }]}
              >
                {!!v.flatData?.thumbnailImage?.length && (
                  <Image
                    source={{ uri: v.flatData?.thumbnailImage[0].url }}
                    style={styles.itemListImage}
                    containerStyle={styles.itemListImageContainer}
                  />
                )}

                <ListItem.Content>
                  <ListItem.Title numberOfLines={1}>{v.flatData?.title}</ListItem.Title>
                </ListItem.Content>
                <Icon type="entypo" name="chevron-right" color={theme.colors.primary} />
              </ListItem>
            ))}
          </View>
        )}

        {!!data.flatData?.users && <Users data={data.flatData.users} />}
      </ScrollView>

      <TabMenus />
    </SafeAreaView>
  )
}

export default BullyScreen

const styles = StyleSheet.create({
  itemListContainer: {
    paddingHorizontal: 20,
    marginHorizontal: 20,
    marginBottom: 10,
    borderRadius: 8,
  },
  itemListImageContainer: {
    width: 70,
    aspectRatio: 1,
    borderRadius: 8,
  },
  itemListImage: {
    width: '100%',
    height: 'auto',
  },
})
